import React from 'react';
import { createStyles, List, makeStyles, Theme } from '@material-ui/core';
import { IconButton, ListItem } from '@mui/material';
import { Link } from 'react-scroll';
import { Button } from '@mui/material';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import { Colors } from '../../enums/enums';

interface Props {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    question: {
      fontSize: '16px',
      font: 'Roboto',
      color: '#0077DB',
      fontWeight: 500,
      top: '32px'
    },
    '@keyframes flicker': {
      from: {
        opacity: 1
      },
      to: {
        opacity: 0.3
      }
    },
    flicker: {
      animationName: '$flicker',
      animationDuration: '1000ms',
      animationIterationCount: 'infinite',
      animationDirection: 'alternate',
      animationTimingFunction: 'ease-in-out'
    }
  })
);

const QuestionsHeaderMentoring = (props: Props) => {
  const classes = useStyles();

  return (
    <div>
      <IconButton
        sx={{ position: 'fixed', right: 45, top: 100 }}
        onClick={() =>
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          })
        }
      >
        <ArrowCircleUpIcon className={classes.flicker} sx={{ fontSize: '3rem' }} color="success" />
      </IconButton>
      <List style={{ marginTop: '20px' }}>
        <ListItem sx={{ cursor: 'pointer' }}>
          <a>
            <Link to="q1" spy={true} smooth={true} className={classes.question}>
              How many sessions are there?
            </Link>
          </a>
        </ListItem>
        <ListItem sx={{ cursor: 'pointer' }}>
          <Link to="q2" spy={true} smooth={true} className={classes.question}>
            What do you look at when making a match?
          </Link>
        </ListItem>
        <ListItem>
          <Link to="q3" spy={true} smooth={true} className={classes.question}>
            How can I prepare for my sessions?
          </Link>
        </ListItem>
        <ListItem sx={{ cursor: 'pointer' }}>
          <Link to="q4" spy={true} smooth={true} className={classes.question}>
            What if I'm not clear about what my goals are?
          </Link>
        </ListItem>
        <ListItem sx={{ cursor: 'pointer' }}>
          <Link to="q5" spy={true} smooth={true} className={classes.question}>
            What happens if session(s) are missed or canceled?
          </Link>
        </ListItem>
        <ListItem sx={{ cursor: 'pointer' }}>
          <Link to="q6" spy={true} smooth={true} className={classes.question}>
            How are sessions scheduled?
          </Link>
        </ListItem>
        <ListItem sx={{ cursor: 'pointer' }}>
          <Link to="q7" spy={true} smooth={true} className={classes.question}>
            What if my mentor or mentee is late more than once?
          </Link>
        </ListItem>
        <ListItem sx={{ cursor: 'pointer' }}>
          <Link to="q8" spy={true} smooth={true} className={classes.question}>
            If my mentor or mentee doesn’t seem engaged, how should I manage this?
          </Link>
        </ListItem>
        <ListItem sx={{ cursor: 'pointer' }}>
          <Link to="q9" spy={true} smooth={true} className={classes.question}>
            What if I come across technical difficulties?
          </Link>
        </ListItem>
        <ListItem sx={{ cursor: 'pointer' }}>
          <Button
            variant="contained"
            color="success"
            style={{ color: 'white', backgroundColor: Colors.ButtonGreen, fontSize: '12px', marginTop: '20px' }}
          >
            {' '}
            <a
              href="mailto:team@locelle.com?subject=[FAQ] New Question"
              style={{ textDecoration: 'none', color: 'white' }}
            >
              Submit a question
            </a>
          </Button>
        </ListItem>
      </List>
    </div>
  );
};

export default QuestionsHeaderMentoring;
