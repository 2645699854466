import { PilotGroupCoachingAssessment } from '../../../models/stateMachineModels';

export const DEFAULT_VALUE_PILOT = {
  groupRating: null,
  challengesExamples: null,
  valuableAspects: null,
  programValue: null,
  referProgram: null,
  otherReferProgram: null,
  improvement: null,
  testimonial: null,
  continue: null
} as PilotGroupCoachingAssessment;
