import api from '../../services/api';

export enum MentorActionTypes {
  CREATE = 'MENTOR_CREATE',
  FETCH = 'MENTOR_FETCH',
  UPDATE = 'MENTOR_UPDATE',
  FIND = 'MENTOR_FIND'
}

export function fetchCurrentMentors() {
  return function (dispatch) {
    return api.get('/mentors').then(({ data }) => {
      console.log('REDUX MENTOR', data);
      dispatch({
        type: MentorActionTypes.FETCH,
        payload: data
      });
    });
  };
}

export function findMentorByMenteeId(menteeId: string) {
  return function (dispatch: (arg0: { type: MentorActionTypes; payload: any }) => void) {
    return api.get('/mentors', { params: { menteeId } }).then(({ data }) => {
      dispatch({
        type: MentorActionTypes.FETCH,
        payload: data
      });
    });
  };
}
export function findMentorByUserId(userId: string) {
  return function (dispatch) {
    return api
      .get('/mentors/profile', {
        params: { userId }
      })
      .then(({ data }) => {
        dispatch({
          type: MentorActionTypes.FIND,
          payload: data
        });
      });
  };
}

export function updateMentor(mentor: { id: string; title: string; linkedin: string; name: string }) {
  return function (dispatch) {
    return api.patch('/mentors', mentor).then(({ data }) => {
      dispatch({
        type: MentorActionTypes.UPDATE,
        payload: data
      });
    });
  };
}

// selectors
export const getMentors = (state) => state.mentors.mentors;

export const getMentor = (state) => state.mentors.mentors;

export const getActiveMentors = (state) =>
  state.mentors.mentors.filter((mentor) => mentor.status === 'ACTIVE' || mentor.status === 'NEW');

export const getMentorMeetingId = (state, id) =>
  state.mentors.mentors.filter((mentor) => mentor.id === id).map((mentor) => mentor.meetingId)[0];
