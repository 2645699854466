import React from 'react';
import { FormHelperText, FormControl, FormLabel, Grid } from '@material-ui/core';
import { useStyles } from '../../styles';
import { useStateMachine } from 'little-state-machine';
import { useForm, Controller } from 'react-hook-form';
import {Fade, Rating, Typography, Button } from '@mui/material';
import { updateAction } from '../actions';
import { IFormStep } from '../midCohortCoacingSurvey.interface';
import StarIcon from '@mui/icons-material/Star';

export const Section1: React.FC<IFormStep> = ({ step, setStep }) => {
  const { state, actions } = useStateMachine({
    updateAction
  });

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: state.midCohortCoachingSurvey?.formData as any
  });
  const classes = useStyles();

  const onSubmit = (data: any) => {
    console.log('onSubmit Step 1: ', data);
    actions.updateAction({ midCohortCoachingSurvey: { formData: data } } as any);
    setStep(step + 1);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Fade in={true}>
      <Grid className={classes.formContent} container spacing={5} alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          <FormControl>
            <FormLabel className={classes.formLabel}>
              On a scale of 1 to 5 how would you rate your overall experience? *
              <Typography sx={{ mt: 2, color: 'grey' }}>1 - highly dissatisfied, 5 - highly satisfied</Typography>{' '}
            </FormLabel>
            <Controller
              render={({ field }) => (
                <Rating
                  {...field}
                  max={5}
                  name="hover-feedback"
                  precision={1}
                  icon={<StarIcon sx={{ color: '#efb906', mr: 4, mb: 2, fontSize: '3rem' }} />}
                  emptyIcon={<StarIcon sx={{ opacity: 0.55, mr: 4, mb: 2, fontSize: '3rem' }} />}
                />
              )}
              rules={{ required: true }}
              name="experienceRating"
              control={control}
            />
            {errors?.experienceRating?.type === 'required' && (
              <FormHelperText style={{ color: 'red' }}>Required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item className={classes.buttonContainter}>
          <Button className={classes.button} variant="contained" color="success" type="submit">
            NEXT
          </Button>
        </Grid>
      </Grid>
      </Fade>
    </form>
  );
};
