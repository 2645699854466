import axios from 'axios';
import { sign } from 'jsonwebtoken';
 
const secret = process.env.REACT_APP_TOKEN_SECRET || '';

const token = sign({}, secret, {
  subject: `LOCELLE:WEB`,
  expiresIn:'1d',
});

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Authorization': `Bearer ${token}`
  }
});

export default api;