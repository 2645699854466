import {OneOnOneFinalAssessment} from '../../../models/stateMachineModels';

export const DEFAULT_VALUE_ONE_ON_ONE_FINAL = {
  rating: null,
  skillsGained: null,
  challengeExamples: null,
  changesNoticed: null,
  valuableAspects: null,
  otherValuableAspects: null,
  gainedInsights: null,
  growth: null,
  referProgram: null,
  otherReferProgram: null,
  continue: null,
  otherContinue: null,
  improvement: null,
  attendWorkshops: null,
  testimonial: null,

} as OneOnOneFinalAssessment;
