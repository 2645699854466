import { makeStyles, createTheme } from '@material-ui/core/styles';
import { Colors } from '../../../enums/enums';

const theme = createTheme({
  palette: {
    primary: {
      main: Colors.ButtonGreen
    },
    background: {
      default: Colors.BackgroundMain
    }
  }
});

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: 'auto',
    marginTop: 50,
    marginBottom: 50,
    maxWidth: 900
  },
  formContainer: {
    padding: '15%',
    paddingTop: 40,
    paddingBottom: 70
  },
  formContent: {
    paddingLeft: '20px',
    paddingRight: '20px'
  },
  formLabel: {
    color: Colors.TextElevated,
    paddingBottom: 10,
    paddingTop: 5,
    lineHeight: '1.4em',
    '&.Mui-focused': {
      color: 'black'
    }
  },
  checkboxLabel: {
    color: '#212121'
  },
  checkboxContainer: {
    marginBottom: 15,
    marginTop: 5
  },
  checkbox: {
    marginRight: 20
  },
  horizontalRadioGroup: {
    marginLeft: '-20px'
  },
  errorText: {
    color: 'red',
    marginTop: 10
  },
  headerBlock: {
    marginBottom: 10
  },
  buttonContainter: {
    marginTop: 30,
    display: 'inline'
  },
  button: {
    padding: '10px 30px',
    borderRadius: 5,
    backgroundColor: Colors.ButtonGreen,
    width: '84px'
  },
  logo: {
    width: '85px'
  }
}));

export { useStyles, theme };