import { createTheme } from "@material-ui/core";


const theme = createTheme({
  palette: {
    primary: {
      main: '#040404'
    },
    secondary: {
      main: '#040404'
    }
  }
});

export default theme;