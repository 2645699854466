import { Stack, Typography } from '@mui/material';
import { Colors, Emails } from '../../enums/enums';

const HomeInactive = () => {
  return (
    <>
      <Stack direction="column" spacing={2} justifyContent="center">
        <Typography variant="h4" sx={{ textAlign: 'center', color: Colors.TextElevated, my: 6 }}>
          Your Account Is Inactive
        </Typography>
        <Typography style={{ textAlign: 'center', fontSize: '1.2rem', color: Colors.Text }}>
          Please reach out to <b>{Emails.MainEmail}</b> to enroll in one of our programs or to get an access to the
          resources.
        </Typography>
      </Stack>
    </>
  );
};

export default HomeInactive;
