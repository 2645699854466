import React from 'react';
import { Box, Typography } from '@mui/material';
import { Colors } from '../../enums/enums';

export default function Footer() {
  return (
    <Box sx={{pt: '280px', backgroundColor: Colors.BackgroundMain}}>
      <Typography
        textAlign="center"
        sx={{
          width: '100%',
          pt: '10px',
          height: '40px',
          color: Colors.TextElevated,
          backgroundColor: Colors.BackgroundMain,
          position: 'fixed',
          bottom: 0,

          // right: '30%'
        }}
      >
        For any questions or technical issues, please contact us at{' '}
        <a style={{ color: 'gray' }} href="mailto:team@locelle.com">
          team@locelle.com
        </a>
      </Typography>
    </Box>
  );
}
