import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grow,
  LinearProgress,
  Paper,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { Colors, GroupStatus, SessionStageType } from '../../enums/enums';
import { useDispatch, useSelector } from 'react-redux';
import { findCoachingSessionsByUserId, getCoachingSessions } from '../../redux/actions/coachingSessions';
import { endOfToday, format, isAfter, isBefore, isToday, parseISO, subHours } from 'date-fns';
import { ICoachingSessionFullDTO, User } from '../Coaching/CoachingSessionFullDTO';
import { CoachingSessionStatus } from '../Coaching/coaching.enum';
import { useHistory } from 'react-router-dom';
import { displaySessionDuration } from '../../utils/basic.utils';
import EventIcon from '@mui/icons-material/Event';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import StarsIcon from '@mui/icons-material/Stars';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LeaderProfileCard from './LeaderProfileCard';
import { FileObjectStatus, IFileObjectDTO } from '../../models/fileObjects';
import { fetchFacilitatorsProfiles, getFacilitators } from '../../redux/actions/content';
import { FILE } from 'dns';
import oneOnOneMidCohortSurvey from '../Tasks/oneOnOneMidCohortSurvey/OneOnOneMidCohortSurvey';

interface CohortDetails {
  totalSessions: number;
  progress: number;
  cohortName: string;
  leader: User;
  participants: User[];
  activeSession: ICoachingSessionFullDTO;
  nextSession: ICoachingSessionFullDTO;
  cohortSessions: ICoachingSessionFullDTO[];
  passedSessions: ICoachingSessionFullDTO[];
  facilitatorProfile: IFileObjectDTO;
}

const UserOverview = () => {
  const dispatch = useDispatch();
  const userId = localStorage.getItem('LOCELLE:USER');
  const allSessions = useSelector(getCoachingSessions);
  const existingFacilitatorProfiles = useSelector(getFacilitators);
  const history = useHistory();

  useEffect(() => {
    dispatch(findCoachingSessionsByUserId(userId));
    dispatch(fetchFacilitatorsProfiles());
  }, [dispatch, userId]);

  useEffect(() => {
    setFacilitators(existingFacilitatorProfiles?.filter((f) => f.status === FileObjectStatus.Active));
  }, [existingFacilitatorProfiles]);

  const [openLeaderProfileDialog, setOpenLeaderProfileDialog] = React.useState(false);
  const [facilitators, setFacilitators] = useState(null);
  const [leader, setLeader] = useState(null);
  const isLeaderOrCoLeader: boolean = allSessions?.some(
    (s) => s.groupDto?.leader?.id === userId || s.groupDto?.coLeader?.id === userId
  );

  const sessions: ICoachingSessionFullDTO[] = allSessions?.filter(
    (s) =>
      (s.stage === SessionStageType.Individual ? s?.participantId === userId : true) &&
      s.groupDto?.leader?.id !== userId
  );
  // sort sessions to be in ascending order
  sessions?.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

  // get cohorts to be used for the dashboard info
  const cohortsToDisplay = sessions
    ?.filter((s) => s.status !== CoachingSessionStatus.Cancelled)
    ?.map((s) => s.group) // Extract the group from each session
    ?.filter(
      (group, index, self) => index === self.findIndex((g) => g.id === group.id) // Filter out duplicates based on group.id
    )
    ?.filter((g) => g.status === GroupStatus.Active);

  // all calculation for how sessions are displayed
  const getCohortDetails = (groupId): CohortDetails => {
    const now = new Date();
    const twoHoursAgo = subHours(now, 2); // 2 hours ago
    const endOfDay = endOfToday();

    const cohortSessions = sessions?.filter(
      (s) => s.group.id === groupId && s?.status !== CoachingSessionStatus.Cancelled
    );
    const totalSessions = cohortSessions?.length || 0;
    const cohortName = cohortSessions[0]?.group?.description;
    const leader = cohortSessions[0]?.groupDto?.leader;
    const passedSessions = cohortSessions?.filter(
      (session) =>
        session?.status === CoachingSessionStatus.Completed ||
        (new Date(session?.date) < now && new Date(session?.date).getTime() < twoHoursAgo.getTime())
    );
    const progress = cohortSessions ? (passedSessions?.length / totalSessions) * 100 : 0;
    const todaySession = cohortSessions
      ?.filter((session) => {
        const sessionDate = new Date(session?.date);
        return (
          session?.status === CoachingSessionStatus.Confirmed &&
          isToday(sessionDate) &&
          isAfter(sessionDate, twoHoursAgo) &&
          isBefore(sessionDate, endOfDay)
        ); // Within the 2-hour past and before the end of the day
      })
      ?.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()) // Sort by closest to now
      ?.find((session) => isAfter(new Date(session?.date), now) || isBefore(new Date(session?.date), now));

    const nextSession = cohortSessions.find(
      (session) => session?.status === CoachingSessionStatus.Confirmed && new Date(session?.date) > now
    );
    const activeSession = todaySession ? todaySession : nextSession;
    const participants = cohortSessions[0]?.groupDto?.participants;

    const facilitatorProfile =
      facilitators?.find((f) => f?.thumbnailName === leader?.id && f?.status === FileObjectStatus.Active) || null;

    return {
      totalSessions,
      cohortName,
      progress,
      participants,
      cohortSessions,
      activeSession,
      nextSession,
      passedSessions,
      leader,
      facilitatorProfile
    };
  };

  const handleClose = (): void => {
    setOpenLeaderProfileDialog(false);
  };

  return (
    <>
      {!isLeaderOrCoLeader && cohortsToDisplay?.length > 0 && (
        <Card
          elevation={0}
          sx={{
            minWidth: '300px',
            backgroundColor: Colors.BackgroundMain,
            boxShadow: 'rgba(0, 0, 0, 0.1) -4px 9px 25px -6px',
            borderRadius: 4,
            maxWidth: '100%',
            '&:hover': {
              boxShadow: '0 0 10px 5px rgba(223, 245, 39, 0.19)'
            }
          }}
        >
          <CardContent>
            <Typography gutterBottom sx={{ fontSize: '1.2rem', ml: 3, color: Colors.TextElevated, fontWeight: 'bold' }}>
              Overview
            </Typography>
            <Box sx={{ width: '100%' }}>
              {cohortsToDisplay?.map((cohort) => {
                const cohortDetails = getCohortDetails(cohort?.id);

                return (
                  <Stack
                    key={cohort.id}
                    spacing={{ xs: 1, sm: 2 }}
                    direction="row"
                    sx={{ flexWrap: 'wrap', mb: 3, alignItems: 'stretch' }}
                  >
                    {/* Cohort Info Section */}
                    <Grow in={true} style={{ transformOrigin: '0 0 0' }} {...{ timeout: 500 }}>
                      <Paper
                        elevation={0}
                        sx={{
                          flexGrow: 1,
                          flexBasis: { xs: '100%', sm: '25%' },
                          display: 'flex',
                          flexDirection: 'column',
                          backgroundColor: Colors.BackgroundMainLighter,
                          p: 1,
                          boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
                          color: Colors.TextElevated,

                          borderRadius: 4,
                          transition: 'box-shadow 0.3s ease-in-out',
                          '&:hover': {
                            boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px'
                          }
                        }}
                      >
                        <Typography gutterBottom sx={{ color: 'green', fontSize: '1rem', textAlign: 'center', mb: 2 }}>
                          Details
                        </Typography>
                        <Divider sx={{ mb: 1, width: '95%', backgroundColor: Colors.BackgroundMainLighter }} />
                        <Box sx={{ ml: 1 }}>
                          <Typography sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                            <span style={{ color: 'grey', marginRight: '8px' }}>Facilitator: </span>
                            <span
                              style={{
                                fontSize: '1.05rem',
                                fontWeight: 'bold',
                                marginRight: '3px',
                                color: Colors.TextElevated
                              }}
                            >
                              {cohortDetails?.leader?.name || ''}
                            </span>
                            {/*only show linkedIn icon if there's a correct link provided*/}
                            {cohortDetails?.leader?.linkedin &&
                              cohortDetails?.leader?.linkedin?.match(/^https:\/\/(www\.)?linkedin\.com\/.*$/) && (
                                <Tooltip title="View LinkedIn Profile" placement="right-end" arrow>
                                  <a href={cohortDetails?.leader?.linkedin} rel="noopener" target="_blank">
                                    <LinkedInIcon sx={{ fontSize: '1.2rem', mt: 0.5 }} color={'primary'} />
                                  </a>
                                </Tooltip>
                              )}
                          </Typography>
                          {cohortDetails?.facilitatorProfile && (
                            <>
                              <Button
                                sx={{
                                  backgroundColor: Colors.TextElevatedLighter,
                                  border: 0,
                                  color: 'white',
                                  width: '185px',
                                  height: '30px',
                                  mb: 2,
                                  mt: 1
                                }}
                                variant="contained"
                                color="success"
                                onClick={() => {
                                  setLeader(cohortDetails?.leader);
                                  setOpenLeaderProfileDialog(true);
                                }}
                              >
                                Facilitator Profile
                              </Button>
                              <LeaderProfileCard
                                isOpen={openLeaderProfileDialog}
                                facilitatorProfile={cohortDetails?.facilitatorProfile}
                                leader={leader}
                                handleClose={handleClose}
                              />
                            </>
                          )}
                          {cohortDetails?.participants?.length > 1 && (
                            <Typography gutterBottom sx={{ color: Colors.TextElevated, fontSize: '1rem' }}>
                              <span style={{ color: 'grey' }}>Program duration: </span>
                              {!!cohortDetails?.cohortSessions?.length
                                ? `${format(
                                    parseISO(cohortDetails?.cohortSessions[0]?.date),
                                    'MMM d, yyyy'
                                  )} - ${format(
                                    parseISO(
                                      cohortDetails?.cohortSessions[cohortDetails?.cohortSessions?.length - 1]?.date
                                    ),
                                    'MMM d, yyyy'
                                  )}`
                                : 'no sessions scheduled yet'}
                            </Typography>
                          )}

                          {!!cohortDetails?.nextSession && (
                            <Typography gutterBottom sx={{ color: Colors.TextElevated, fontSize: '1rem' }}>
                              <span style={{ color: 'grey' }}>Next session: </span>
                              {cohortDetails?.nextSession
                                ? format(parseISO(cohortDetails?.nextSession?.date), 'EEE, MMM d, yyyy - h mm a')
                                : ''}
                            </Typography>
                          )}

                          {cohortDetails?.participants?.length > 1 && (
                            <Typography gutterBottom sx={{ color: Colors.TextElevated, fontSize: '1rem' }}>
                              <span style={{ color: 'grey' }}>Your group: </span>
                              {cohortDetails?.participants?.length} participants
                            </Typography>
                          )}
                          <Typography gutterBottom sx={{ color: Colors.TextElevated, fontSize: '1rem' }}>
                            <span style={{ color: 'grey' }}>Number of sessions Scheduled: </span>
                            {cohortDetails?.totalSessions || 0}
                          </Typography>
                        </Box>
                        {cohortDetails?.progress === 100 && (
                          <Typography
                            gutterBottom
                            sx={{
                              color: Colors.TextElevatedLighter,
                              fontSize: '1.1rem',
                              mt: 'auto',
                              textAlign: 'center',
                              fontWeight: 'bold',
                              py: 1,
                              mx: 1,
                              mb: 1,
                              backgroundColor: Colors.BackgroundDrawer,
                              borderRadius: 2
                            }}
                          >
                            All sessions completed. Great job!
                          </Typography>
                        )}
                      </Paper>
                    </Grow>

                    {/* Participants Section HIDDEN TEMPORARYLY */}
                    {/*{cohortDetails?.participants?.length > 1 && (*/}
                    {/*  <Grow in={true} style={{ transformOrigin: '0 0 0' }} {...{ timeout: 1000 }}>*/}
                    {/*    <Paper*/}
                    {/*      sx={{*/}
                    {/*        flexGrow: 1,*/}
                    {/*        flexBasis: { xs: '100%', sm: '15%' },*/}
                    {/*        textAlign: 'center',*/}
                    {/*        backgroundColor: Colors.BackgroundMainLighter,*/}
                    {/*        color: Colors.TextElevated,*/}
                    {/*        fontSize: '1rem',*/}
                    {/*        p: 1,*/}
                    {/*        boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',*/}
                    {/*        borderRadius: 4,*/}
                    {/*        position: 'relative',*/}
                    {/*        transition: 'box-shadow 0.3s ease-in-out',*/}
                    {/*        '&:hover': {*/}
                    {/*          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px'*/}
                    {/*        }*/}
                    {/*      }}*/}
                    {/*    >*/}
                    {/*      <Typography*/}
                    {/*        gutterBottom*/}
                    {/*        sx={{ color: 'green', fontSize: '1rem', textAlign: 'center', mb: 2 }}*/}
                    {/*      >*/}
                    {/*        Participants*/}
                    {/*      </Typography>*/}
                    {/*      <Divider*/}
                    {/*        sx={{ mb: 2, width: '95%', backgroundColor: Colors.BackgroundMainLighter, mx: 'auto' }}*/}
                    {/*      />*/}
                    {/*      <Box sx={{ mb: 6 }}>*/}
                    {/*        {cohortDetails?.participants*/}
                    {/*          ?.sort((a, b) => (a.id === userId ? -1 : b.id === userId ? 1 : 0)) // put participant on top of the list*/}
                    {/*          .map((participant) => (*/}
                    {/*            <Box*/}
                    {/*              key={participant?.id}*/}
                    {/*              sx={{ display: 'flex', alignItems: 'center', mb: 0.8, ml: 1 }}*/}
                    {/*            >*/}
                    {/*              <Avatar*/}
                    {/*                sx={{*/}
                    {/*                  backgroundColor: Colors.TextElevated,*/}
                    {/*                  width: 24,*/}
                    {/*                  height: 24,*/}
                    {/*                  fontSize: '0.9rem'*/}
                    {/*                }}*/}
                    {/*              >*/}
                    {/*                {participant?.name?.charAt(0)}*/}
                    {/*              </Avatar>*/}
                    {/*              <Box sx={{ ml: 1 }}>{participant?.id === userId ? 'You' : participant?.name}</Box>*/}
                    {/*            </Box>*/}
                    {/*          ))}*/}
                    {/*      </Box>*/}
                    {/*    </Paper>*/}
                    {/*  </Grow>*/}
                    {/*)}*/}

                    {/* Sessions Section */}
                    <Grow in={true} style={{ transformOrigin: '0 0 0', cursor: 'pointer' }} {...{ timeout: 1500 }}>
                      <Paper
                        sx={{
                          flexGrow: 1,
                          flexBasis: { xs: '100%', sm: '50%' },
                          textAlign: 'center',
                          backgroundColor: Colors.BackgroundMainLighter,
                          color: Colors.TextElevated,
                          fontSize: '1rem',
                          p: 1,
                          boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
                          borderRadius: 4,
                          transition: 'box-shadow 0.3s ease-in-out',
                          '&:hover': {
                            boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px'
                          }
                        }}
                      >
                        <Typography
                          gutterBottom
                          sx={{
                            color: 'green',
                            fontSize: '1rem',
                            textAlign: 'center',
                            mb: 2
                          }}
                        >
                          Sessions
                        </Typography>
                        {/*Linear Progress line*/}
                        <Box sx={{ marginBottom: 2, display: 'flex', alignItems: 'center' }}>
                          <Box sx={{ width: '100%' }}>
                            <LinearProgress
                              variant="determinate"
                              value={cohortDetails?.progress}
                              sx={{
                                height: '15px',
                                borderRadius: 3,
                                mx: 'auto',
                                backgroundColor: Colors.Active,
                                '& .MuiLinearProgress-bar': {
                                  backgroundColor: Colors.TextElevatedLighter
                                }
                              }}
                            />
                          </Box>
                          <Box>
                            <Typography variant="body2" sx={{ color: 'text.secondary', ml: 0.5 }}>{`${Math.round(
                              cohortDetails?.progress
                            )}%`}</Typography>
                          </Box>
                        </Box>
                        {cohortDetails?.cohortSessions?.map((session, index) => (
                          <Box
                            key={session?.id}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyItems: 'flex-start',
                              mb: 0.8,
                              cursor: 'pointer',
                              mx: 1,
                              borderRadius: 2,
                              backgroundColor:
                                session?.date === cohortDetails?.activeSession?.date
                                  ? Colors.SelectedDrawerBackground
                                  : null,
                              color:
                                session?.date === cohortDetails?.activeSession?.date
                                  ? 'green'
                                  : session?.date < cohortDetails?.activeSession?.date
                                  ? 'gray'
                                  : Colors.TextElevated
                            }}
                            onClick={() => {
                              history?.push('/sessions');
                            }}
                          >
                            {cohortDetails?.passedSessions?.map((s) => s?.id)?.includes(session?.id) && (
                              <CheckCircleIcon
                                fontSize="medium"
                                sx={{
                                  color: Colors.TextElevatedLighter,
                                  mr: 1
                                }}
                              />
                            )}
                            {session?.date > cohortDetails?.activeSession?.date && (
                              <EventIcon sx={{ color: Colors.ButtonGreen, mr: 1 }} fontSize="medium" />
                            )}
                            {session?.date === cohortDetails?.activeSession?.date && (
                              <StarsIcon sx={{ mr: 1 }} fontSize="medium" />
                            )}

                            <Box
                              sx={{
                                ml: 1,
                                fontWeight: 'bold',
                                width: '55%',
                                textAlign: 'start',
                                pb: 0.1,
                                mx: 'auto'
                              }}
                            >
                              {session?.topic ||
                                (session?.stage === SessionStageType.MeetAndGreet ? 'First Session' : session?.stage)}
                              :
                            </Box>
                            <Box
                              sx={{
                                ml: 1,
                                width: '45%',
                                textAlign: 'start',
                                mx: 'auto'
                              }}
                            >
                              {format(parseISO(session?.date), 'EEE, MMM d, yyyy - h mm a')} (
                              {displaySessionDuration(session?.duration)})
                            </Box>
                          </Box>
                        ))}
                      </Paper>
                    </Grow>
                  </Stack>
                );
              })}
            </Box>
          </CardContent>
        </Card>
      )}
    </>
  );
};

export default UserOverview;