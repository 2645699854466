import React, { useEffect } from 'react';
import { Avatar, Card, CardContent, CardHeader, MuiThemeProvider } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import { resetPassword } from '../../services/auth0';
import theme from '../../utils/theme';
import { findUserProfile, getUserProfile, updateUser } from '../../redux/actions/user';
import { Button, Stack, Typography } from '@mui/material';
import { Colors } from '../../enums/enums';
import { toast } from 'react-toastify';
import { useAuth0 } from '@auth0/auth0-react';
import { Controller, useForm } from 'react-hook-form';

interface Props {
  internalUser: {
    id: string;
    name: string;
    email: string;
    email2: string;
    type: string;
    clientId: string;
    avatar: string;
    createdAt: string;
    updatedAt: string;
    isCoach: boolean;
    isCoachee: boolean;
    linkedin: string;
    title: string;
    company: string;
    age: string;
    experience: string;
    city: string;
    country: string;
    timezone: string;
    status: string;
    careerGoals: [{ date: string; goal: string }];
    note: string;
  };
}

const UserProfile: React.FC<Props> = ({ internalUser }: Props) => {
  const dispatch = useDispatch();
  console.log('Internal User >>>', internalUser);
  const { user, isLoading } = useAuth0();
  const userId = localStorage.getItem('LOCELLE:USER');
  const userProfile = useSelector(getUserProfile);

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues: {
      name: null,
      linkedin: null,
      email2: null,
      title: null,
      careerGoals: null
    }
  });

  useEffect(() => {
    const latestGoal = userProfile?.careerGoals?.reduce((latest, current) => {
      return new Date(latest.date) > new Date(current.date) ? latest : current;
    });
    reset({
      name: userProfile?.name?.trim(),
      linkedin: userProfile?.linkedin?.trim(),
      email2: userProfile?.email2?.trim(),
      title: userProfile?.title?.trim(),
      careerGoals: latestGoal?.goal
    });
  }, [userProfile]);

  const updateProfile = (data) => {
    data.userId = userId;
    data.careerGoals = { date: new Date(), goal: data.careerGoals };
    console.log('Update Profile payload:', data);
    try {
      dispatch(updateUser(data));
    } catch (e) {
      console.error('Error updating user: ', e);
    }
  };

  useEffect(() => {
    if (internalUser) {
      dispatch(findUserProfile(userId));
    }
  }, [internalUser]);

  const trimInput = (event, fieldName): void => {
    const trimmedValue = event?.target?.value?.trim();
    setValue(fieldName, trimmedValue);
  };

  return (
    <>
      <MuiThemeProvider theme={theme}>
        <Card
          elevation={5}
          style={{
            marginTop: 20,
            paddingTop: 20,
            paddingBottom: 30,
            paddingLeft: 10,
            height: '740px',
            backgroundColor: Colors.BackgroundMainLighter
          }}
        >
          <CardHeader
            avatar={
              <Avatar
                style={{
                  width: '60px',
                  height: '60px',
                  backgroundColor: Colors.Text,
                  fontSize: '2.2rem',
                  color: Colors.BackgroundDrawer
                }}
              >
                {userProfile?.name?.charAt(0)}
              </Avatar>
            }
            title={
              <Typography sx={{ color: Colors.TextElevated, fontWeight: 'bold', fontSize: '1.1rem' }}>
                {userProfile?.name}
              </Typography>
            }
            subheader={`${userProfile?.title} at ${userProfile?.company}`}
          />

          <form onSubmit={handleSubmit(updateProfile)}>
            <Stack direction="row" sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
              <CardContent>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      sx={{ minWidth: { xs: '100%', md: '350px' } }}
                      label="Name"
                      placeholder="enter your name"
                      color="success"
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="outlined"
                      onBlur={(event) => trimInput(event, 'name')}
                    />
                  )}
                  name="name"
                  control={control}
                />
              </CardContent>

              <CardContent>
                <TextField
                  disabled
                  color="success"
                  sx={{ minWidth: { xs: '100%', md: '350px' } }}
                  label="Primary Email"
                  value={userProfile?.email}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </CardContent>
            </Stack>
            <Stack direction="row" sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
              <CardContent>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      sx={{ minWidth: { xs: '100%', md: '350px' } }}
                      label="Alternate Email"
                      color="success"
                      InputLabelProps={{
                        shrink: true
                      }}
                      type="email"
                      placeholder="enter your alternate email"
                      variant="outlined"
                      onBlur={(event) => trimInput(event, 'email2')}
                    />
                  )}
                  name="email2"
                  control={control}
                />
              </CardContent>

              <CardContent>
                <TextField
                  disabled
                  sx={{ minWidth: { xs: '100%', md: '350px' } }}
                  label="Company"
                  value={userProfile?.company}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </CardContent>
            </Stack>

            <CardContent>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    sx={{ minWidth: { xs: '100%', md: '730px' } }}
                    label="Job Title"
                    color="success"
                    InputLabelProps={{
                      shrink: true
                    }}
                    placeholder="enter your title"
                    variant="outlined"
                    onBlur={(event) => trimInput(event, 'title')}
                  />
                )}
                name="title"
                control={control}
              />
            </CardContent>

            <CardContent>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    sx={{ minWidth: { xs: '100%', md: '730px' } }}
                    label="LinkedIn Profile"
                    color="success"
                    helperText="Copy and paste the entire profile link including https://"
                    placeholder="i.e. https://www.linkedin.com/in/your-profile/"
                    InputLabelProps={{
                      shrink: true
                    }}
                    variant="outlined"
                    onBlur={(event) => trimInput(event, 'linkedin')}
                  />
                )}
                name="linkedin"
                control={control}
              />
            </CardContent>

            <CardContent>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    sx={{ minWidth: { xs: '100%', md: '730px' } }}
                    label="My Career Goals"
                    multiline
                    rows={3}
                    color="success"
                    placeholder="enter your career goals"
                    InputLabelProps={{
                      shrink: true
                    }}
                    variant="outlined"
                    // onBlur={(event) => trimInput(event, 'linkedin')}
                  />
                )}
                name="careerGoals"
                control={control}
              />
            </CardContent>

            <CardContent>
              <Typography fontSize="small" sx={{ mb: 2, mt: -2, color: Colors.TextElevated }}>
                To update your Profile, simply edit the needed field and then click the Update Profile button below.
              </Typography>
              <Stack direction="row" spacing={3} sx={{ mt: 1 }}>
                <Button sx={{ backgroundColor: Colors.ButtonGreen }} type="submit" variant="contained" color="success">
                  Update Profile
                </Button>
                <Button
                  variant="outlined"
                  color="success"
                  onClick={() => {
                    resetPassword(internalUser.email);
                    toast.success('Please check your primary email to reset the password');
                  }}
                >
                  Reset Password
                </Button>
              </Stack>
            </CardContent>
          </form>
        </Card>
      </MuiThemeProvider>
    </>
  );
};

export default UserProfile;