import 'react-multi-carousel/lib/styles.css';
import { Card, CardMedia, CardContent, Typography, Box, Grid } from '@mui/material';
import * as React from 'react';
import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { fetchLearningTools, getLearningTools } from '../../redux/actions/content';
import { useDispatch, useSelector } from 'react-redux';
import { Colors, ResourceStatus, ResourcePlacement } from '../../enums/enums';

const useStyles = makeStyles({
  root: {
    width: '340px',
    height: '270px',
    marginLeft: '20px',
    marginBottom: '30px',
    transition: 'box-shadow 0.3s ease-in-out',
    '&:hover': {
      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px'
    }
  }
});

export default function ResourcesGrid(userObject) {
  const userClientId = userObject?.user?.clientId;
  const classes = useStyles();
  const dispatch = useDispatch();
  const learningTools = useSelector(getLearningTools);

  useEffect(() => {
    dispatch(fetchLearningTools());
  }, []);

  const activeHomepageLearningTools = learningTools?.filter(
    (lt) => lt.status === ResourceStatus.Active && lt.placement === ResourcePlacement.Homepage
  );
  const learningToolsWithRestrictions = activeHomepageLearningTools?.filter((lt) =>
    lt.clientIds ? lt?.clientIds?.includes(userClientId) : true
  );

  return (
      <Box>
        <Grid container spacing={1}>
          {learningToolsWithRestrictions?.map((item) => (
              <Grid item xs={12} sm={6} md={3} key={item?.id}>
                <a target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: 'black' }} href={item?.azureUrl}>
                  <Card className={classes.root}>
                    <CardMedia sx={{ height: '180px' }} component="img" image={item?.thumbnailUrl} />
                    <CardContent>
                      <Typography textAlign="center" variant="h6" component="div" sx={{ color: Colors.TextElevated }}>
                        {item.title}
                      </Typography>
                    </CardContent>
                  </Card>
                </a>
              </Grid>
          ))}
        </Grid>
      </Box>
  );
}