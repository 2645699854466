import { combineReducers, Reducer } from 'redux';
import { SurveysReducer } from './surveys';
import { MenteesReducer } from './mentees';
import { SessionsReducer } from './sessions';
import { MatchesReducer } from './matches';
import { SurveysResponseReducer } from './surveysResponse';
import { MentorsReducer } from './mentors';
import { UserReducer } from './users';
import { CoachingSessionsReducer } from './coachingSessions';
import { TasksReducer } from './tasks';
import { ContentReducer } from './content';

export const allReducers: Reducer = combineReducers({
  surveys: SurveysReducer,
  mentees: MenteesReducer,
  sessions: SessionsReducer,
  coachingSessionsReducer: CoachingSessionsReducer,
  matches: MatchesReducer,
  surveysResponse: SurveysResponseReducer,
  mentors: MentorsReducer,
  users: UserReducer,
  tasks: TasksReducer,
  content: ContentReducer
});
