import { AnyAction } from 'redux';
import { UserActionTypes } from '../actions/user';
import { IInternalUser, IUserProfile } from '../types';
import {MenteeActionTypes} from "../actions/mentees";

const initialState = {
  users: [] as IInternalUser[],
  profile: [] as IUserProfile[],
  sessionLeader: null
};

export function UserReducer(state = initialState, action: AnyAction) {
  if (!state) {
    return initialState;
  }

  switch (action.type) {
    case UserActionTypes.FIND_INTERNAL_USER:
      return {
        ...state,
        users: action.payload
      };

    case UserActionTypes.FIND_USER_PROFILE:
      return {
        ...state,
        profile: action.payload
      };

    case UserActionTypes.FETCH_SESSION_LEADER:
      return {
        ...state,
        sessionLeader: action.payload
      };

    case UserActionTypes.UPDATE:
      return {
        ...state,
        profile: action.payload
      }

    default:
      return state;
  }
}
