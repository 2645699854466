import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { routes } from '../../routes'; 

const WebPortal = () => {
    return (
        <Switch>
        {routes.map((route, index) => {
           return (
           <Route
               key={index}
               path={route.path}
               exact={route.exact}
               component={((props: any) => {
               return (
                   <route.layout {...props}>
                   <route.component {...props} />
                   </route.layout>
               );
               })}
           />
           );
       })}
       
    </Switch>
    );
};

export default WebPortal;