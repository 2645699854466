import { FinalSessionCoachingSurvey } from '../../../models/stateMachineModels';

export const DEFAULT_VALUE = {
  groupRating: null,
  privateRating: null,
  skillsGained: null,
  examples: null,
  changes: null,
  valuableAspects: null,
  gainedInsights: null,
  otherGainedInsights: null,
  betterLeader: null,
  referProgram: null,
  otherReferProgram: null,
  improvement: null,
  attendWorkshops: null,
  testimonial: null,
  portalImprovement: null
} as FinalSessionCoachingSurvey;
