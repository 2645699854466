import * as React from 'react';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import HomeOutlinedIcon from '@material-ui/icons/Home';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import PersonOutlineOutlinedIcon from '@material-ui/icons/Person';
import { Card } from '@material-ui/core';
import NavBar from '../../components/general/NavBar';
import { NavLink, useLocation } from 'react-router-dom';
import GroupsIcon from '@mui/icons-material/Groups';
import HelpIcon from '@mui/icons-material/Help';
import Logout from '@mui/icons-material/Logout';
import { useAuth0 } from '@auth0/auth0-react';
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { Badge, IconButton } from '@mui/material';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import { Colors, MenuTypes, UserStatus } from '../../enums/enums';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { findInternalUser, getInternalUser } from '../../redux/actions/user';
import { findCoachingSessionsByUserId, getCoachingSessions } from '../../redux/actions/coachingSessions';
import { fetchUserTaskAndResponses, getUserTasksAndResponses } from '../../redux/actions/tasks';
import { ICoachingSessionFullDTO } from '../Coaching/CoachingSessionFullDTO';
import { UserTasksAndResponses } from '../../models/userTasksAndResponses';

const drawerWidth = 100;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      backgroundColor: Colors.BackgroundMain
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0
      },
      zIndex: 1
    },
    appBar: {
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth
      },
      backgroundColor: '#fff',
      color: '#342E40'
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none'
      }
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: Colors.BackgroundDrawer
      // boxShadow: "0 0.125rem 9.375rem rgb(90 97 105 / 10%), 0 0.25rem 0.5rem rgb(90 97 105 / 12%), 0 0.9375rem 1.375rem rgb(90 97 105 / 10%), 0 0.4375rem 2.1875rem rgb(165 182 201 / 10%)"
      // border: 0,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(1),
      width: '100%',
      height: '100%',
      backgroundColor: Colors.BackgroundMain,
      borderRadius: '12px',
      marginTop: '20px'
    },
    menuCaption: {
      // fontFamily: `'Roboto', sans-serif`,
      // fontSize: '0.875rem',
      // fontWeight: 500,
      color: Colors.TextElevated,
      // padding: '6px',
      // textTransform: 'capitalize',
      marginTop: '30px',
      fontFamily: 'Epilogue',
      fontWeight: 800,
      fontSize: '20px',
      marginLeft: '25%'
    },
    listItemIcon: {
      width: '70px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      fontFamily: 'Epilogue',
      fontWeight: 500,
      fontSize: '14px',
      color: Colors.Text,
      '&:hover': {
        color: Colors.MenuText
      }

      // color: '#383131'
    },
    sidebarMenuItem: {
      // color: 'black',
      marginTop: '10px'
    },
    activeItemIcon: {
      width: '70px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      fontFamily: 'Epilogue',
      fontWeight: 500,
      fontSize: '14px',
      color: Colors.MenuText
    },
    activeMenuItem: {
      borderLeft: '5px solid #342E40',
      paddingLeft: '11px',
      backgroundColor: Colors.SelectedDrawerBackground,
      color: Colors.MenuText
    },
    hoverMenuItem: {
      '&:hover': {
        borderLeft: '5px solid #342E40',
        paddingLeft: '11px',
        backgroundColor: Colors.SelectedDrawerBackground
      },
      color: Colors.MenuText
    }
  })
);

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  // window?: () => Window;
  isSignedIn?: (val: any) => void;
}

const ResponsiveDrawer: React.FC<Props> = ({ children }) => {
  // const { window } = props;
  const { user } = useAuth0();
  const internalUser = useSelector(getInternalUser);
  const { sub, email } = user;
  const userId = localStorage.getItem('LOCELLE:USER');
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);
  const { logout } = useAuth0();
  const location = useLocation();
  const isMobile = width <= 768;
  const sessions: ICoachingSessionFullDTO[] = useSelector(getCoachingSessions);
  const userTasksAndResponses: UserTasksAndResponses = useSelector(getUserTasksAndResponses);
  //temporary measure for leaders to only see terms and conditions as task
  const leaderIds = sessions?.map((s) => s.groupDto.leader.id);
  const isLeader: boolean = leaderIds?.includes(userId);
  let numberToSubtract = 0;

  if (userTasksAndResponses?.termsAndConditions?.submitted && isLeader) {
    numberToSubtract = 1;
  }
  const groupId = sessions?.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())[0]?.groupId;

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    if (user) {
      dispatch(findInternalUser(sub, email));
    }
  }, [user]);

  useEffect(() => {
    if (userId && groupId) {
      dispatch(fetchUserTaskAndResponses(userId, groupId));
    }
    if (userId) {
      dispatch(findCoachingSessionsByUserId(userId));
    }
  }, [dispatch, userId, groupId]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const counterNumber =
    isLeader && userTasksAndResponses?.tasksCount ? 1 - numberToSubtract : userTasksAndResponses?.tasksCount;
  console.log('USER', internalUser);
  const navItemsCommon =
    internalUser?.status === UserStatus.Active
      ? [
          { title: MenuTypes.Home, icon: <HomeOutlinedIcon style={{ transform: `scale(1.8)` }} />, url: '/' },
          {
            title: MenuTypes.Profile,
            icon: <PersonOutlineOutlinedIcon style={{ transform: `scale(1.8)` }} />,
            url: '/profile'
          },
          { title: MenuTypes.Sessions, icon: <GroupsIcon style={{ transform: `scale(1.8)` }} />, url: '/sessions' },
          {
            title: MenuTypes.Tasks,
            icon: (
              <Badge color="error" badgeContent={counterNumber || 0}>
                <TaskAltIcon style={{ transform: `scale(1.8)` }} />
              </Badge>
            ),
            url: '/tasks'
          },
          {
            title: MenuTypes.Learning,
            icon: <SchoolRoundedIcon style={{ transform: `scale(1.8)` }} />,
            url: '/resources'
          },
          { title: MenuTypes.FAQ, icon: <HelpIcon style={{ transform: `scale(1.8)` }} />, url: '/faq' }
        ]
      : [{ title: MenuTypes.Home, icon: <HomeOutlinedIcon style={{ transform: `scale(1.8)` }} />, url: '/' }];

  const drawer = (
    <div style={{ paddingTop: '70px' }}>
      <List
        subheader={
          <Typography variant="caption" className={classes.menuCaption} display="block" gutterBottom></Typography>
        }
      >
        {navItemsCommon.map((item, index) => (
          <NavLink key={index} to={item.url} style={{ color: 'black', textDecoration: 'none' }}>
            <ListItem
              button
              style={{ height: '80px' }}
              className={location.pathname === item.url ? classes.activeMenuItem : classes.hoverMenuItem}
              key={index}
              onClick={() => setActiveMenu(index)}
            >
              <ListItemIcon className={location.pathname === item.url ? classes.activeItemIcon : classes.listItemIcon}>
                {navItemsCommon[index].icon}
                <span className={classes.sidebarMenuItem}>{item.title}</span>
              </ListItemIcon>
            </ListItem>
          </NavLink>
        ))}
        <Divider />
        <ListItem
          button
          style={{ height: '80px' }}
          className={classes.hoverMenuItem}
          onClick={() => {
            localStorage.clear();
            logout();
          }}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <Logout />
            <span className={classes.sidebarMenuItem}>Log Out</span>
          </ListItemIcon>
        </ListItem>
      </List>
    </div>
  );

  const container = document.body;

  return (
    <div className={classes.root} style={{ backgroundColor: Colors.BackgroundMain }}>
      <CssBaseline />
      <NavBar />
      {isMobile && (
        <AppBar position="fixed" className={classes.appBar} elevation={0}>
          <Toolbar>
            <IconButton
              // color="secondary"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      )}
      <Card elevation={6} className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            container={container}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper
            }}
            open
            color="inherit"
          >
            {drawer}
          </Drawer>
        </Hidden>
      </Card>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
};

export default ResponsiveDrawer;