import { AnyAction } from "redux";
import { MenteeActionTypes } from "../actions/mentees";

  const initialState = {
    mentee: [],
  }

  export function MenteesReducer(state = initialState, action: AnyAction){

    if (!state) {
      return initialState;
    }

    switch(action.type){

      case MenteeActionTypes.FIND: 
        return {
          ...state,
          mentee: action.payload
        }
      
        case MenteeActionTypes.UPDATE: 
        return {
          ...state,
          mentee: action.payload
        }
        
      default:
        return state;
    }

    
  }

  