import React, { useEffect } from 'react';
import { FormControl, FormLabel, Grid } from '@material-ui/core';
import { useStyles } from '../../styles';
import { useStateMachine } from 'little-state-machine';
import { useForm, Controller } from 'react-hook-form';
import { IFormStep } from '../finalSessionCoacingSurvey.interface';
import { updateAction } from '../actions';
import { TextField, Button, Fade } from '@mui/material';

export const Section11: React.FC<IFormStep> = ({ step, setStep }) => {
  const { state, actions } = useStateMachine({
    updateAction
  });
  const { handleSubmit, getValues, control } = useForm({
    defaultValues: state.finalSessionCoachingSurvey.formData as any
  });
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleBack = () => {
    const partialData = getValues();
    actions.updateAction({ finalSessionCoachingSurvey: { formData: partialData } } as any);
    setStep(step - 1);
  };

  const onSubmit = (data: any) => {
    console.log('onSubmit Step 11: ', data);
    actions.updateAction({ finalSessionCoachingSurvey: { formData: data } } as any);
    setStep(step + 1);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Fade in={true}>
        <Grid className={classes.formContent} container spacing={5} alignItems="center" justifyContent="center">
          <Grid container item xs={12} direction="column">
            <FormControl>
              <FormLabel className={classes.formLabel}>
                Would you like to attend virtual monthly leadership topics workshops led by experts?
              </FormLabel>
              <Controller
                render={({ field }) => (
                  <TextField
                    multiline
                    placeholder="Type your answer here..."
                    fullWidth
                    color="success"
                    {...field}
                    sx={{ mb: 4 }}
                  />
                )}
                name="attendWorkshops"
                control={control}
              />
            </FormControl>
          </Grid>

          <Grid item className={classes.buttonContainter}>
            <Button className={classes.button} variant="outlined" color="success" onClick={() => handleBack()}>
              BACK
            </Button>
          </Grid>

          <Grid item className={classes.buttonContainter}>
            <Button className={classes.button} variant="contained" color="success" type="submit">
              NEXT
            </Button>
          </Grid>
        </Grid>
      </Fade>
    </form>
  );
};
