import React, { useEffect } from 'react';
import { FormControl, FormLabel, FormHelperText, Grid } from '@material-ui/core';
import { useStyles } from '../../styles';
import { useStateMachine } from 'little-state-machine';
import { Controller, useForm } from 'react-hook-form';
import { Fade, Button, FormGroup, TextField } from '@mui/material';
import { IFormStep } from '../oneOnOneFinalAssessment.interface';
import { updateAction } from './oneOnOneFinalActions';

export const Section5: React.FC<IFormStep> = ({ step, setStep }) => {
  const { state, actions } = useStateMachine({
    updateAction
  });

  const {
    handleSubmit,
    getValues,
    register,
    watch,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: state.oneOnOneFinalAssessment.formData as any
  });
  const classes = useStyles();
  const watchValuableAspects = watch('valuableAspects');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleBack = () => {
    const partialData = getValues();
    actions.updateAction({ oneOnOneFinalAssessment: { formData: partialData } } as any);
    setStep(step - 1);
  };

  const onSubmit = (data: any) => {
    console.log('onSubmit Step 5: ', data);
    actions.updateAction({ oneOnOneFinalAssessment: { formData: data } } as any);
    setStep(step + 1);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Fade in={true}>
        <Grid className={classes.formContent} container spacing={5} alignItems="center" justifyContent="center">
          <Grid container item xs={12} direction="column">
            <FormControl>
              <FormLabel className={classes.formLabel} style={{ marginTop: '-60px' }}>
                What aspects of the program did you find most valuable or impactful? *
              </FormLabel>
              <FormGroup>
                {[
                  'My mentor/coach',
                  'Resources',
                  'Topics and Content (if applicable)',
                  'Ease of use/connectivity',
                  'Other'
                ].map((optionValue, index) => (
                  <Grid item xs={12} key={index}>
                    <FormLabel key={optionValue} className={classes.checkbox}>
                      <input
                        {...register('valuableAspects', { required: true })}
                        type="checkbox"
                        value={optionValue}
                        name="valuableAspects"
                        style={{
                          margin: '20px 30px 20px 30px',
                          transform: 'scale(1.5)'
                        }}
                      />
                      <span style={{ fontSize: '18px', color: 'grey', marginTop: '5px' }}>{optionValue}</span>
                    </FormLabel>
                  </Grid>
                ))}
              </FormGroup>

              {errors.valuableAspects?.type === 'required' && (
                <FormHelperText style={{ color: 'red' }}>Required</FormHelperText>
              )}
            </FormControl>
            {watchValuableAspects && watchValuableAspects.includes('Other') && (
              <Grid item xs={8} style={{ marginLeft: '20px' }}>
                <br />
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="Type your answer here..."
                      required
                      color="success"
                      fullWidth
                      variant="outlined"
                    />
                  )}
                  name="otherValuableAspects"
                  control={control}
                />
              </Grid>
            )}
          </Grid>

          <Grid item className={classes.buttonContainter}>
            <Button className={classes.button} variant="outlined" color="success" onClick={() => handleBack()}>
              BACK
            </Button>
          </Grid>

          <Grid item className={classes.buttonContainter}>
            <Button className={classes.button} variant="contained" color="success" type="submit">
              NEXT
            </Button>
          </Grid>
        </Grid>
      </Fade>
    </form>
  );
};
