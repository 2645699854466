import React from 'react';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Fade from '@mui/material/Fade';
import { TransitionProps } from '@mui/material/transitions';
import MUIDataTable from 'mui-datatables';
import { Box, Link, Tooltip, Typography } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import DialogContent from '@mui/material/DialogContent';
import { Colors } from '../../enums/enums';
import { User } from './CoachingSessionFullDTO';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

interface Props {
  isOpen: boolean;
  participants?: User[];
  handleClose: () => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Fade ref={ref} {...props} timeout={{ enter: 100, exit: 100 }} />;
});

const GroupParticipantsDetails = ({ isOpen, handleClose, participants }: Props) => {
  const columns = [
    {
      name: 'id',
      options: {
        display: false
      }
    },
    {
      name: 'linkedin',
      label: '',
      options: {
        display: false
      }
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        sort: true,
        customBodyRender: (v, x) => {
          return (
            <ListItem>
              <ListItemAvatar>
                <Avatar sx={{ color: 'white', backgroundColor: Colors.TextElevated }}>{v.charAt(0)}</Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <React.Fragment>
                    <Typography sx={{ color: Colors.TextElevated }}>
                      <b>{v}</b>
                      <Link
                        href={x?.rowData[1]?.includes('linkedin.com') ? x?.rowData[1] : null}
                        target="_blank"
                        rel="noopener"
                        underline="none"
                        sx={{ cursor: x?.rowData[1] ? 'pointer' : '', color: 'grey' }}
                      >
                        <Tooltip placement="top" arrow title="View LinkedIn Profile">
                          <LinkedInIcon
                            color={!x?.rowData[1] || !x?.rowData[1]?.includes('linkedin.com') ? 'disabled' : 'primary'}
                            sx={{ ml: 0.5 }}
                            fontSize="small"
                          />
                        </Tooltip>
                      </Link>
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
          );
        }
      }
    },
    {
      name: 'careerGoals',
      label: 'Career Goal',
      options: {
        sort: true,
        customBodyRender: (v, x) => {
          const latestGoal = v?.reduce((latest, current) => {
            return new Date(current.date) > new Date(latest.date) ? current : latest;
          });
          return <Typography sx={{ maxWidth: '400px' }}>{latestGoal?.goal}</Typography>;
        }
      }
    },
    {
      name: 'title',
      label: 'Job Title',
      options: {
        sort: true
      }
    },
    {
      name: 'city',
      label: 'City',
      options: {
        sort: true
      }
    },
    {
      name: 'country',
      label: 'Country',
      options: {
        sort: true
      }
    },
    {
      name: 'timezone',
      label: 'Timezone',
      options: {
        sort: true
      }
    }
  ];

  const options = {
    filter: false,
    print: true,
    sort: true,
    viewColumns: false,
    selectableRows: false
  };

  return (
    <Dialog fullScreen open={isOpen} onClose={handleClose} TransitionComponent={Transition}>
      <AppBar sx={{ position: 'relative', backgroundColor: Colors.SelectedDrawerBackground }}>
        <Toolbar>
          <Typography variant="h5" component="div" sx={{ flexGrow: 1, color: Colors.TextElevated, fontWeight: 'bold' }}>
            Cohort Participants Info
          </Typography>
          <IconButton edge="end" color="success" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <MUIDataTable data={participants} columns={columns} options={options} />
      </DialogContent>
    </Dialog>
  );
};

export default GroupParticipantsDetails;