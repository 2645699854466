import React from 'react';
import DialogContent from '@mui/material/DialogContent';
import { Box, FormControl, FormLabel, Rating, DialogTitle, Dialog, Button, Typography, TextField } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import StarIcon from '@mui/icons-material/Star';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import HomeIcon from '@mui/icons-material/Home';
import { useHistory } from 'react-router-dom';
import { TaskType } from '../../../enums/enums';

interface Props {
  isOpen: boolean;
  taskId: string;
  groupId: string;
  handleClose: () => void;
}

const FirstSessionEvaluation = (props: Props) => {
  const [value, setValue] = React.useState<number | null>(null);
  const [hover, setHover] = React.useState(-1);
  const userId = localStorage.getItem('LOCELLE:USER');
  const history = useHistory();

  const submitResponse = (data): void => {
    try {
      const surveyData = {
        userId: userId,
        taskId: props.taskId,
        groupId: props.groupId,
        taskName: TaskType.FirstSessionAssessment,
        formResponse: data
      };
      api
        .post('/tasks/response', surveyData)
        .then(({ data }) => {
          toast.success('Thank you for submitting your evaluation');
          props.handleClose();
        })
        .catch(() => toast.error('Error submitting response'));
    } catch (e) {
      toast.error(`Error submitting response ${e?.data?.message}`);
    }
  };

  const labels: { [index: string]: string } = {
    1: 'Very Poor',
    2: 'Poor',
    3: 'Ok',
    4: 'Good',
    5: 'Very Good'
  };

  function getLabelText(value: number) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
  }

  const { handleSubmit, control } = useForm({
    defaultValues: {
      rating: null,
      comment: null
    }
  });

  return (
    <Dialog open={props.isOpen} onClose={props.handleClose}>
      <DialogTitle sx={{ fontSize: '1.5rem', ml: 3 }}>
        <Button
          startIcon={<HomeIcon />}
          sx={{
            position: 'absolute',
            right: '16px',
            backgroundColor: '#9f9595',
            color: '#FFFFFF',

            width: 190,
            '&:hover': {
              backgroundColor: '#7c7272'
            }
          }}
          onClick={() => {
            history.push(`/tasks`);
            props.handleClose();
          }}
        >
          Return to Portal
        </Button>
        <Typography sx={{ fontSize: '1.6rem', mt: 20 }}>First Session Evaluation</Typography>
        <Typography>Approximate completion time: 30 seconds</Typography>
      </DialogTitle>
      <DialogContent sx={{ height: '450px', width: '450px', mt: '50px' }}>
        <form onSubmit={handleSubmit(submitResponse)}>
          <FormControl>
            <FormLabel style={{ marginLeft: '30px', marginTop: '20px', fontSize: '1.1rem' }}>
              How would you rate your first session? *
            </FormLabel>
            <Controller
              render={({ field }) => (
                <Rating
                  {...field}
                  sx={{ mt: 3, ml: 3 }}
                  name="hover-feedback"
                  value={value}
                  precision={1}
                  getLabelText={getLabelText}
                  onChange={(event, newValue) => {
                    field.onChange(newValue);
                    setValue(newValue);
                  }}
                  onChangeActive={(event, newHover) => {
                    setHover(newHover);
                  }}
                  icon={<StarIcon sx={{ color: '#eaaa05', mr: 3, fontSize: '2.5rem', mb: 2 }} />}
                  emptyIcon={<StarIcon sx={{ opacity: 0.55, fontSize: '2.5rem', mr: 3, mb: 2 }} />}
                />
              )}
              name="rating"
              control={control}
            />
            {value !== null && <Box sx={{ ml: 20 }}>{labels[hover !== -1 ? hover : value]}</Box>}
          </FormControl>

          <FormControl>
            <FormLabel style={{ marginLeft: '30px', marginTop: '20px', fontSize: '1.1rem' }}>
              Do you have any additional comments?
            </FormLabel>
            <Controller
              render={({ field }) => (
                <TextField multiline rows={4} fullWidth color="success" {...field} sx={{ mb: 2, mt: 2, ml: 3 }} />
              )}
              name="comment"
              control={control}
            />
          </FormControl>
          <Button
            variant="contained"
            color="success"
            style={{ position: 'absolute', bottom: '20px', right: '16px' }}
            type="submit"
          >
            Submit
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default FirstSessionEvaluation;