import { SessionDurationMinutes } from '../enums/enums';

export const noop = () => {
  // do nothing
};

export const handleCaughtApiError = () => {
  return (error) => {
    if (error.response) {
      console.log('Error: ' + JSON.stringify(error));
    } else if ('Error: ' + error.request) {
      // The request was made but no response was received
      console.log('Error: ' + error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error: ' + error.message);
    }
  };
};

export const getHoursLeftForTheDay = (): number => {
  const currentDate = new Date();
  // 1: Set the time to midnight for the current date
  const startOfDay = new Date(currentDate);
  startOfDay.setHours(0, 0, 0, 0);
  // 2: Get the current time
  const currentTime = currentDate.getTime();
  // 3: Calculate the difference between the end of the day and the current time
  const endOfDay = new Date(startOfDay);
  endOfDay.setDate(endOfDay.getDate() + 1); // Move to the next day
  const timeDifference = endOfDay.getTime() - currentTime;
  // 4: Convert the time difference from milliseconds to hours
  const hoursRemaining = timeDifference / (60 * 60 * 1000);
  return Math.floor(hoursRemaining);
};

export const displaySessionDuration = (duration: number): string => {
  let result = '';
  switch (duration) {
    case SessionDurationMinutes.Sixty:
      result = '1 hour';
      break;
    case SessionDurationMinutes.Ninety:
      result = '1 h 30 min';
      break;
    case SessionDurationMinutes.HundredFive:
      result = '1 h 45 min';
      break;
    case SessionDurationMinutes.HundredTwenty:
      result = '2 hours';
      break;

    default:
      result = duration + ' mins';
      break;
  }
  return result;
};
