import { GlobalState } from 'little-state-machine';

export function updateAction(state: GlobalState, payload: GlobalState): GlobalState {
  return {
    ...state,
    pilotGroupAssessment: {
      ...state.pilotGroupAssessment,
      ...payload.pilotGroupAssessment
    }
  };
}
