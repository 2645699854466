import axios, { Method } from "axios";
import  env  from '../utils/env';


export function resetPassword(email:string){
  let method: Method = 'POST';
  var options = {
    method,
    url: `https://${env.DOMAIN}/dbconnections/change_password`,
    headers: {'content-type': 'application/json'},
    data: {
      client_id: `${env.CLIENTID || 'iwQBhLzL1IWFuwDjT96KG23s55sJIwiD'}`,
      email: email,
      connection: 'Username-Password-Authentication'
    }
  };
  
  axios.request(options).then(function (response) {
    console.log(response.data);
  }).catch(function (error) {
    console.error(error);
  });
}

export function changePassword(userId: string, newPassword: string){
  let method: Method = 'PATCH';
  var options = {
    method,
    url: `https://${env.DOMAIN}/api/v2/users/${userId}`,
    headers: {'content-type': 'application/json'},
    data: {password: newPassword, connection: 'Username-Password-Authentication'}
  };
  
  axios.request(options).then(function (response) {
    console.log(response.data);
  }).catch(function (error) {
    console.error(error);
  });
}
