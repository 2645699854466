import api from '../../services/api';
import { ICoachingSessionFullDTO } from '../../pages/Coaching/CoachingSessionFullDTO';
import { toast } from 'react-toastify';
import { handleCaughtApiError } from '../../utils/basic.utils';
import { CoachingSessionStatus } from '../../pages/Coaching/coaching.enum';

export enum CoachingSessionActionTypes {
  FETCH = 'COACHING_SESSION_FETCH',
  UPDATE = 'COACHING_SESSION_UPDATE'
}

/**
 * Update session status by User based on completeUserIds field
 */
// function updateStatus(data: ICoachingSessionFullDTO[], userId: string) {
//   data.forEach((s) => {
//     if (s.status === CoachingSessionStatus.Confirmed && s.completeUserIds?.includes(userId)) {
//       s.status = CoachingSessionStatus.Completed;
//     }
//   });
// }

export function findCoachingSessionsByUserId(userId: string) {
  return function (dispatch) {
    return api.get(`/coaching-sessions/${userId}`).then((response) => {
      const data: ICoachingSessionFullDTO[] = response.data;
      dispatch({
        type: CoachingSessionActionTypes.FETCH,
        payload: data
      });
    });
  };
}

export function cancelCoachingSession(id: string, userId: string) {
  return function (dispatch) {
    return api
      .patch('/coaching-sessions/cancel', { id, userId })
      .then(({ data }) => {
        dispatch({
          type: CoachingSessionActionTypes.UPDATE,
          payload: data
        });
        toast.success('Successfully cancelled session');
      })
      .catch(handleCaughtApiError);
  };
}

export function markCompleteCoachingSession(id: string, status: string, date: boolean) {
  return function (dispatch) {
    return api
      .patch('/coaching-sessions', { id, status, date })
      .then(({ data }) => {
        toast.success('Successfully marked session complete');
        dispatch({
          type: CoachingSessionActionTypes.UPDATE,
          payload: data
        });
      })
      .catch(handleCaughtApiError);
  };
}

export const getCoachingSessions = (state): ICoachingSessionFullDTO[] => state.coachingSessionsReducer.data;
