import api from '../../services/api';

export enum TasksActionTypes {
  FETCH_TASKS_RESPONSES = 'FETCH_TASKS_RESPONSES',
  SET_COUNT = 'SET_COUNT'
}

export function fetchUserTaskAndResponses(userId: string, groupId: string) {
  return function (dispatch) {
    return api.get(`/tasks/response-user/${userId}/${groupId}`).then(({ data }) => {
      dispatch({
        type: TasksActionTypes.FETCH_TASKS_RESPONSES,
        payload: data
      });
    });
  };
}

export function fetchUserTaskAndResponsesNoGroup(userId: string) {
  return function (dispatch) {
    return api.get(`/tasks/response-user/${userId}`).then(({ data }) => {
      dispatch({
        type: TasksActionTypes.FETCH_TASKS_RESPONSES,
        payload: data
      });
    });
  };
}

export function setTasksCount(count: number) {
  return function (dispatch) {
    dispatch({
      type: TasksActionTypes.SET_COUNT,
      payload: count
    });
  };
}

export const getUserTasksAndResponses = (state) => state.tasks.userTasksAndResponses;
