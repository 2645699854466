import React, { useEffect } from 'react';
import { FormControl, FormLabel, FormHelperText, Grid, FormControlLabel } from '@material-ui/core';
import { useStyles } from '../../styles';
import { useStateMachine } from 'little-state-machine';
import { useForm, Controller } from 'react-hook-form';
import { IFormStep } from '../midCohortCoacingSurvey.interface';
import { updateAction } from '../actions';
import { TextField, Button, Fade, Radio, RadioGroup } from '@mui/material';

export const Section4: React.FC<IFormStep> = ({ step, setStep }) => {
  const { state, actions } = useStateMachine({
    updateAction
  });
  const {
    handleSubmit,
    getValues,
    control,
    watch,
    formState: { errors }
  } = useForm({
    defaultValues: state.midCohortCoachingSurvey.formData as any
  });
  const classes = useStyles();
  const watchSlackChannel = watch('slackChannel');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleBack = () => {
    const partialData = getValues();
    actions.updateAction({ midCohortCoachingSurvey: { formData: partialData } } as any);
    setStep(step - 1);
  };

  const onSubmit = (data: any) => {
    console.log('onSubmit Step 4: ', data);
    actions.updateAction({ midCohortCoachingSurvey: { formData: data } } as any);
    setStep(step + 1);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Fade in={true}>
        <Grid className={classes.formContent} container spacing={5} alignItems="center" justifyContent="center">
          <Grid container item xs={12} direction="column">
            <FormControl>
              <FormLabel className={classes.formLabel}>Is having a slack/teams channel valuable to you? *</FormLabel>
              <Controller
                render={({ field }) => (
                  <RadioGroup style={{ marginLeft: 50 }} {...field}>
                    {['Yes', 'No', 'Other'].map((aspect, index) => (
                      <FormControlLabel
                        key={index}
                        value={aspect}
                        control={<Radio color="success" style={{ marginRight: 10 }} />}
                        label={aspect}
                        style={{ marginBottom: 8, scale: '110%' }}
                      />
                    ))}
                  </RadioGroup>
                )}
                name="slackChannel"
                control={control}
                rules={{ required: true }}
              />
              {errors?.slackChannel?.type === 'required' && (
                <FormHelperText className={classes.errorText}>Required</FormHelperText>
              )}
            </FormControl>
            {watchSlackChannel && watchSlackChannel.includes('Other') && (
              <Grid item xs={8} style={{ marginLeft: '20px' }}>
                <br />
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="Type your answer here..."
                      required
                      fullWidth
                      color="success"
                      variant="outlined"
                    />
                  )}
                  name="otherSlackChannel"
                  control={control}
                />
              </Grid>
            )}
          </Grid>

          <Grid item className={classes.buttonContainter}>
            <Button className={classes.button} variant="outlined" color="success" onClick={() => handleBack()}>
              BACK
            </Button>
          </Grid>

          <Grid item className={classes.buttonContainter}>
            <Button className={classes.button} variant="contained" color="success" type="submit">
              NEXT
            </Button>
          </Grid>
        </Grid>
      </Fade>
    </form>
  );
};
