import React, { useState } from 'react';
import { Grid, FormHelperText, FormControl, FormControlLabel } from '@material-ui/core';
import { useStyles } from '../../styles';
import { useStateMachine } from 'little-state-machine';
import { useForm, Controller } from 'react-hook-form';
import { IFormProps } from './section.interface';
import {
  Stack,
  Typography,
  Button,
  Box,
  Autocomplete,
  TextField,
  RadioGroup,
  Radio,
  Alert,
  AlertTitle
} from '@mui/material';
import { Colors } from '../../../../../enums/enums';
import { TIMEZONES } from '../../../../../constants/constants';
import api from '../../../../../services/api';
import { updateAction } from '../../actions';
import { toast } from 'react-toastify';

const Section1: React.FC<IFormProps> = ({ step, setStep }) => {
  const classes = useStyles();
  const { state, actions } = useStateMachine({
    updateAction
  });
  const [userExists, setUserExists] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: state?.formDetails?.all
  });

  //validate user email not used
  const checkUserExists = async (email: string): Promise<boolean> => {
    let exists = false;
    await api
      .get(`/users//check-email/${email}`, {})
      .then(({ data }) => {
        exists = data;
        if (data) {
          console.log('User with the following email already exists:', email);
          toast.error('User already exists');
        } else {
          console.log('New User with the following email can be created', email);
        }
      })
      .catch((error) => {
        console.log('Error checking if user email is used', error);
      });

    setUserExists(exists);

    return exists;
  };

  const onSubmit = async (data: any) => {
    const email = data?.email;
    const userEmailExists = await checkUserExists(email);

    if (!userEmailExists) {
      actions.updateAction({ formDetails: { all: data } } as any);
      setStep(step + 1);
    }
  };

  const validateEmail = (value) => {
    if (/[A-Z]/.test(value)) {
      return 'Email cannot contain capital letters';
    }
    return true;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={5} alignItems="center" justifyContent="center">
        <Stack sx={{ width: '667px', ml: 4 }} spacing={3}>
          <Box>
            <Typography sx={{ my: 2, color: Colors.TextElevated }}>
              Email
              <Typography sx={{ color: 'grey', fontSize: '0.9rem', my: 2 }}>
                Please do not use capital letters
              </Typography>
            </Typography>
            {userExists && (
              <Alert
                sx={{ mb: 2 }}
                severity="error"
                onClose={() => {
                  setUserExists(false);
                }}
              >
                <AlertTitle>Error</AlertTitle>
                User with this email address already exists. Please use another email if you'd like to register a new
                user or contact team@locelle.com if you need an assistance.
              </Alert>
            )}
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Primary Work Email Address *"
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true
                  }}
                  color="success"
                  error={!!errors.email}
                />
              )}
              name="email"
              control={control}
              rules={{
                required: true,
                pattern: { value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, message: 'Please enter a valid email' },
                validate: validateEmail
              }}
            />
            {errors?.email?.type === 'required' && (
              <FormHelperText className={classes.errorText}>Required</FormHelperText>
            )}
            {errors?.email?.type === 'pattern' && (
              <FormHelperText className={classes.errorText}>{errors?.email?.message}</FormHelperText>
            )}
            {errors?.email?.type === 'validate' && (
              <FormHelperText className={classes.errorText}>Email cannot contain capital letters</FormHelperText>
            )}

            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Alternate Email Address "
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true
                  }}
                  color="success"
                  sx={{ mt: 3 }}
                />
              )}
              name="alternateEmail"
              control={control}
              rules={{
                pattern: { value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, message: 'Please enter a valid email' }
              }}
            />
            {errors?.alternateEmail?.type === 'pattern' && (
              <FormHelperText className={classes.errorText}>{errors.alternateEmail.message}</FormHelperText>
            )}
          </Box>

          <Box>
            <Typography sx={{ my: 2, color: Colors.TextElevated }}>Name</Typography>
            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  label="First Name *"
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true
                  }}
                  color="success"
                />
              )}
              name="firstName"
              control={control}
              rules={{ required: true }}
            />
            {errors?.firstName?.type === 'required' && (
              <FormHelperText className={classes.errorText}>Required</FormHelperText>
            )}

            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Last Name *"
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true
                  }}
                  color="success"
                  sx={{ mt: 3 }}
                />
              )}
              name="lastName"
              control={control}
              rules={{ required: true }}
            />
            {errors?.lastName?.type === 'required' && (
              <FormHelperText className={classes.errorText}>Required</FormHelperText>
            )}
          </Box>

          <Box>
            <Typography sx={{ my: 2, color: Colors.TextElevated }}>Location</Typography>
            <FormControl style={{ width: '100%' }}>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="City *"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    color="success"
                  />
                )}
                name="city"
                control={control}
                rules={{ required: true }}
              />
              {errors?.city?.type === 'required' && (
                <FormHelperText className={classes.errorText}>Required</FormHelperText>
              )}
            </FormControl>
          </Box>

          <Box>
            <FormControl style={{ width: '100%' }}>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Country *"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    color="success"
                  />
                )}
                name="country"
                control={control}
                rules={{ required: true }}
              />
              {errors?.country?.type === 'required' && (
                <FormHelperText className={classes.errorText}>Required</FormHelperText>
              )}
            </FormControl>
          </Box>

          <Box>
            <FormControl style={{ width: '100%' }}>
              <Controller
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    id="timezone"
                    sx={{ width: '667px' }}
                    options={TIMEZONES}
                    autoHighlight
                    getOptionLabel={(option) => option}
                    onChange={(event, newInputValue) => {
                      field.onChange(newInputValue);
                    }}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {option}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Timezone *"
                        InputLabelProps={{
                          shrink: true
                        }}
                        variant="outlined"
                        color="success"
                      />
                    )}
                  />
                )}
                name="timezone"
                control={control}
                rules={{ required: true }}
              />
              {errors?.timezone?.type === 'required' && (
                <FormHelperText className={classes.errorText}>Required</FormHelperText>
              )}
            </FormControl>
          </Box>

          <Box>
            <FormControl>
              <Typography sx={{ my: 2, color: Colors.TextElevated, fontSize: '16px' }}>Approximate age *</Typography>
              <Typography sx={{ mb: 1, color: Colors.TextElevated, fontSize: '14px' }}>
                Your age group helps us understand different perspectives and ensures our initiatives are relevant to
                all demographics.
              </Typography>
              <Typography sx={{ mb: 1, color: Colors.TextElevated, fontSize: '14px' }}>
                Your response is used for anonymous internal research, will not be shared externally, and will not
                appear on your profile.{' '}
              </Typography>
              <Controller
                render={({ field }) => (
                  <RadioGroup {...field} row>
                    {['18-24', '25-34', '35-44', '45-54', '55 and up', 'Prefer not to say'].map((age, index) => (
                      <FormControlLabel
                        style={{ color: Colors.TextElevated }}
                        key={index}
                        value={age}
                        control={<Radio color="success" />}
                        label={age}
                      />
                    ))}
                  </RadioGroup>
                )}
                name="age"
                control={control}
                rules={{ required: true }}
              />
              {errors?.age?.type === 'required' && (
                <FormHelperText className={classes.errorText}>Required</FormHelperText>
              )}
            </FormControl>
          </Box>
        </Stack>
        <Grid item className={classes.buttonContainter}>
          <Button className={classes.button} variant="contained" color="success" type="submit">
            NEXT
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default Section1;