import React, { useEffect } from 'react';
import { FormControl, FormHelperText, FormLabel, Grid } from '@material-ui/core';
import { useStyles } from '../../styles';
import { useStateMachine } from 'little-state-machine';
import { useForm, Controller } from 'react-hook-form';
import { Fade, Button, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { IFormStep } from '../oneOnOneFinalAssessment.interface';
import { updateAction } from './oneOnOneFinalActions';

export const Section7: React.FC<IFormStep> = ({ step, setStep }) => {
  const { state, actions } = useStateMachine({
    updateAction
  });
  const {
    handleSubmit,
    getValues,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: state.oneOnOneFinalAssessment.formData as any
  });
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleBack = () => {
    const partialData = getValues();
    actions.updateAction({ oneOnOneFinalAssessment: { formData: partialData } } as any);
    setStep(step - 1);
  };

  const onSubmit = (data: any) => {
    console.log('onSubmit Step 7: ', data);
    actions.updateAction({ oneOnOneFinalAssessment: { formData: data } } as any);
    setStep(step + 1);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Fade in={true}>
        <Grid className={classes.formContent} container spacing={5} alignItems="center" justifyContent="center">
          <Grid container item xs={12} direction="column">
            <FormControl>
              <FormLabel className={classes.formLabel}>Do you think the program helped you learn and grow? *</FormLabel>
              <Controller
                render={({ field }) => (
                  <RadioGroup style={{ marginLeft: 50 }} {...field}>
                    {['Yes', 'No'].map((aspect, index) => (
                      <FormControlLabel
                        key={index}
                        value={aspect}
                        control={<Radio color="success" style={{ marginRight: 10 }} />}
                        label={aspect}
                        style={{ marginBottom: 8, scale: '110%' }}
                      />
                    ))}
                  </RadioGroup>
                )}
                name="growth"
                control={control}
                rules={{ required: true }}
              />
              {errors?.growth?.type === 'required' && (
                <FormHelperText className={classes.errorText}>Required</FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item className={classes.buttonContainter}>
            <Button
              className={classes.button}
              style={{ marginTop: -10 }}
              variant="outlined"
              color="success"
              onClick={() => handleBack()}
            >
              BACK
            </Button>
          </Grid>

          <Grid item className={classes.buttonContainter}>
            <Button
              className={classes.button}
              style={{ marginTop: -10 }}
              variant="contained"
              color="success"
              type="submit"
            >
              NEXT
            </Button>
          </Grid>
        </Grid>
      </Fade>
    </form>
  );
};
