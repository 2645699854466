import { GlobalState } from 'little-state-machine';

export function updateAction(state: GlobalState, payload: GlobalState): GlobalState {
  return {
    ...state,
    formDetails: {
      ...state.formDetails,
      ...payload.formDetails
    }
  };
}
