import { Stack, Typography } from '@mui/material';
import React, { ReactElement } from 'react';
import { createStyles, Link, makeStyles, Theme } from '@material-ui/core';
import {Colors} from "../../enums/enums";

interface Props {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divQuestion: {
      marginTop: '50px',
      backgroundColor: Colors.BackgroundDrawer,
      padding: '20px 20px 20px 20px'
      // height: '200px'
    },
    question: {
      fontFamily: 'Roboto',
      fontSize: '24px',
      fontWeight: 'normal'
    },
    answer: {
      marginTop: '10px'
    },
    button: {
      backgroundColor: '#db0054',
      color: '#ffffff',
      padding: '10px 20px',
      '&:hover': {
        backgroundColor: '#C5004B'
      }
    }
  })
);

function QuestionsBodyCoaching(props: Props): ReactElement {
  const classes = useStyles();

  return (
    <Stack direction="column" spacing={2}>
      <>
        <div id="q1" style={{ height: '20px' }} />
        <div id="q1" className={classes.divQuestion}>
          <span className={classes.question}>What is coaching, and how can it benefit me?</span>
          <Typography className={classes.answer}>
            At BestHuman (by Locelle), we follow ICF’s definition of Coaching as well as code of ethics. ICF is the gold
            standard in coaching and defines coaching as partnering with clients in a thought-provoking and creative
            process that inspires them to maximize their personal and professional potential. The process of coaching
            often unlocks previously untapped sources of imagination, productivity and leadership.
          </Typography>
        </div>
      </>
      <>
        <div id="q2" style={{ height: '20px' }} />
        <div id="q2_" className={classes.divQuestion}>
          <span className={classes.question}>How does the coaching platform work?</span>
          <Typography className={classes.answer}>
            Interested participants onboard on the platform, get paired with a group and an executive coach. Our team
            takes care of all logistics such as setting up the groups, set up your sessions and share all content you
            need to make the most of the program.
          </Typography>
        </div>
      </>
      <>
        <div id="q3" style={{ height: '20px' }} />
        <div id="q3_" className={classes.divQuestion}>
          <span className={classes.question}>What types of coaching services are available on the platform?</span>
          <Typography>Group Coaching (various levels of leadership) and 1:1</Typography>
        </div>
      </>
      <>
        <div id="q4" style={{ height: '20px' }} />
        <div id="q4_" className={classes.divQuestion}>
          <span className={classes.question}>How do you choose the right coach for my needs?</span>
          <Typography>
            In this leadership learning program, all of our coaches are ICF Certified and are experts in what they do.
            We pick coaches based on the group’s profile, level in their career as well as goals and needs.
          </Typography>
        </div>
      </>
      <div id="q5" style={{ height: '20px' }} />
      <div id="q5_" className={classes.divQuestion}>
        <span className={classes.question}>How do I schedule a coaching session?</span>
        <Typography>
          The team at Locelle takes your availability as well as your groups’ into consideration and set it up for you.
          You will also receive calendar invites and reminders to ensure you attend all sessions.
        </Typography>
      </div>
      <div id="q6" style={{ height: '20px' }} />
      <div id="q6_" className={classes.divQuestion}>
        <span className={classes.question}>
          What are the qualifications and credentials of the coaches on the platform?
        </span>
        <Typography>
          All of our coaches have globally recognised coaching credentials as well as industry experience backed by many
          hundreds and thousands of hours of coaching. They also go through training specifically for the program.
        </Typography>
      </div>
      <div id="q7" style={{ height: '20px' }} />
      <div id="q7_" className={classes.divQuestion}>
        <span className={classes.question}>Can I change coaches if I'm not satisfied with my current one? </span>
        <Typography>
          <ul>
            <li>In a 1:1 scenario, yes you can. </li>
            <li>
              In a group setting, if it’s after the first session, we can try to switch your group and coach but after
              that, it may not be possible. We ask after the first session (survey) to rate your experience so if it’s
              not a great one, we make it so.
            </li>
          </ul>
        </Typography>
      </div>
      <div id="q8" style={{ height: '20px' }} />
      <div id="q8_" className={classes.divQuestion}>
        <span className={classes.question}>Is my information and data kept confidential and secure?</span>
        <Typography>
          Your data and information will be kept confidential and secure. The sessions must be confidential between
          individuals/groups and the coach.
        </Typography>
      </div>
      <div id="q9" style={{ height: '20px' }} />
      <div id="q9_" className={classes.divQuestion}>
        <span className={classes.question}>How long are coaching sessions?</span>
        <Typography>
          <ul>
            <li>1:1 sessions can be 30 mins to 1 hour</li>
            <li>Group sessions are all 1 hour at this time</li>
          </ul>
        </Typography>
      </div>
      <div id="q10" style={{ height: '20px' }} />
      <div id="q10_" className={classes.divQuestion}>
        <span className={classes.question}>Can I have coaching sessions at my preferred time and timezone?</span>
        <Typography>
          <ul>
            <li>In 1:1, absolutely. </li>
            <li>In a group setting, we do a poll to ensure sessions are at a time and date that work for everyone. </li>
          </ul>
        </Typography>
      </div>
      <div id="q11" style={{ height: '20px' }} />
      <div id="q11_" className={classes.divQuestion}>
        <span className={classes.question}>What if I need to reschedule or cancel a coaching session?</span>
        <Typography>
          <ul>
            <li>
              1:1 sessions can be rescheduled if 7 days notice is given. For no shows, the client will be billed for the
              time.
            </li>
            <li>
              Group sessions can’t be rescheduled as they are set up in advance and take into account everyone’s
              schedule and time.
            </li>
            <li>If a coach cancels, those sessions will be rescheduled.</li>
          </ul>
        </Typography>
      </div>
      <div id="q12" style={{ height: '20px' }} />
      <div id="q12_" className={classes.divQuestion}>
        <span className={classes.question}>Can I access coaching materials or resources outside of the sessions?</span>
        <Typography>Yes, you can.</Typography>
      </div>
      <div id="q13" style={{ height: '20px' }} />
      <div id="q13_" className={classes.divQuestion}>
        <span className={classes.question}>Can I provide feedback or rate my coaching experience?</span>
        <Typography>100% - in fact, we appreciate your feedback and experience. It is how we improve.</Typography>
      </div>
      <div id="q14" style={{ height: '20px' }} />
      <div id="q14_" className={classes.divQuestion}>
        <span className={classes.question}>What if I have technical issues during a coaching session?</span>
        <Typography>
          Try refreshing the screen to resolve the issue. If it persists, please email{' '}
          <a href="mailto:team@locelle.com?subject=[FAQ] Technical Issue Question">team@locelle.com </a>
          immediately and we will look into it for you.
        </Typography>
      </div>
      <div id="q15" style={{ height: '20px' }} />
      <div id="q15_" className={classes.divQuestion}>
        <span className={classes.question}>How do I join coaching sessions?</span>
        <Typography>
          Go to the coaching tab and join the relevant session (date/time) using our video platform. You also get
          calendar invites for all sessions.
        </Typography>
      </div>
      <div id="q16" style={{ height: '20px' }} />
      <div id="q16_" className={classes.divQuestion}>
        <span className={classes.question}>
          Are there any additional support or resources available besides one-on-one coaching?
        </span>
        <Typography>
          We provide quarterly leadership roundtable sessions, leadership development template, meeting notes and
          workbook to support your learning.
        </Typography>
      </div>
      <div id="q17" style={{ height: '20px' }} />
      <div id="q17_" className={classes.divQuestion}>
        <span className={classes.question}>Do I get a certificate?</span>
        <Typography>
          Participants can get a certificate if they are enrolled in a learning program. To gain certification,
          participants are expected to attend 80% of sessions, complete their leadership plan and all assessments.{' '}
        </Typography>
      </div>
    </Stack>
  );
}

export default QuestionsBodyCoaching;
