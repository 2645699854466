import React from 'react';
import { FormControl, FormLabel, Grid } from '@material-ui/core';
import { useStyles } from '../../styles';
import { useStateMachine } from 'little-state-machine';
import { useForm, Controller } from 'react-hook-form';
import { Button, TextField } from '@mui/material';
import api from '../../../../services/api';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IFormStep } from '../oneOnOneFinalAssessment.interface';
import { updateAction } from './oneOnOneFinalActions';
import { TaskType } from '../../../../enums/enums';

interface IFormStepExtended extends IFormStep {
  userId: string;
  taskId: string;
  groupId: string;
}

export const Section12: React.FC<IFormStepExtended> = ({ step, setStep, userId, taskId, groupId }) => {
  const { state, actions } = useStateMachine({
    updateAction
  });

  const history = useHistory();

  const { handleSubmit, getValues, control, formState } = useForm({
    defaultValues: state.oneOnOneFinalAssessment.formData as any
  });

  const classes = useStyles();

  const handleBack = () => {
    const partialData = getValues();
    actions.updateAction({ oneOnOneFinalAssessment: { formData: partialData } } as any);
    setStep(step - 1);
  };

  const handleComplete = (data): void => {
    const formData = data;
    const payload = {
      userId: userId,
      taskId: taskId,
      groupId: groupId,
      taskName: TaskType.OneOnOneFinalSurvey,
      formResponse: formData
    };
    const url = '/tasks/response';
    api
      .post(url, payload)
      .then(() => toast.success('Thanks for completing and taking the time to reflect!'))
      .catch(() => toast.error('Error submitting Final Session Feedback'))
      .finally(() => history.push('/tasks'));

    actions.updateAction({
      oneOnOneFinalAssessment: {
        formData: null
      }
    } as any);
  };

  const onSubmit = (data: any): void => {
    console.log('One on One Survey FormData: ', data);
    actions.updateAction({
      oneOnOneFinalAssessment: {
        formData: {
          ...data
        }
      }
    } as any);

    handleComplete(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid className={classes.formContent} container spacing={5} alignItems="center" justifyContent="center">
        <Grid container item xs={12} direction="column">
          <FormControl>
            <FormLabel className={classes.formLabel}>
              Do you have a testimonial you would like to share about your Mentor/Coach and overall experience?
            </FormLabel>
            <Controller
              render={({ field }) => (
                <TextField
                  multiline
                  rows={6}
                  placeholder="Type your answer here..."
                  fullWidth
                  color="success"
                  {...field}
                />
              )}
              name="testimonial"
              control={control}
            />
          </FormControl>
        </Grid>

        <Grid item className={classes.buttonContainter}>
          <Button className={classes.button} variant="outlined" color="success" onClick={() => handleBack()}>
            BACK
          </Button>
        </Grid>

        <Grid item className={classes.buttonContainter}>
          <Button className={classes.button} variant="contained" color="success" type="submit">
            SUBMIT
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
