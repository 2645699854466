import api from '../../services/api';

export enum SurveyActionTypes {
  CREATE = 'SURVEY_CREATE',
  FETCH = 'SURVEY_FETCH',
  FILTER = 'SURVEY_FILTER',
  FETCH_RESPONSE = 'SURVEY_RESPONSE',
  FETCH_RESPONSES_BY_USERID = 'FETCH_USER_RESPONSES_BY_USERID '
}

export function fetchCurrentSurveysResponse(email: string) {
  return function (dispatch) {
    return api
      .get('/surveys/result', {
        params: { email }
      })
      .then(({ data }) => {
        dispatch({
          type: SurveyActionTypes.FETCH_RESPONSE,
          payload: data
        });
      });
  };
}

export function fetchUserSurveysResponses(userId: string) {
  return function (dispatch) {
    return api.get(`/surveys/response-user/${userId}`).then(({ data }) => {
      dispatch({
        type: SurveyActionTypes.FETCH_RESPONSES_BY_USERID,
        payload: data
      });
    });
  };
}

export function sendSurveysResponse(surveyResponse) {
  return function (dispatch) {
    return api
      .post('/surveys/response', surveyResponse)
      .then(({ data }) => {
        dispatch({
          type: SurveyActionTypes.CREATE,
          payload: data
        });
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          console.log('Error: ' + error.response.data);
          console.log('Error: ' + error.response.status);
          console.log('Error: ' + error.response.headers);
        } else if ('Error: ' + error.request) {
          // The request was made but no response was received
          console.log('Error: ' + error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          alert('Error: ' + error.message);
        }
      });
  };
}

export function sendFinalSurveysResponse(surveyResponse) {
  return function (dispatch) {
    return api
      .post('/surveys/feedback', surveyResponse)
      .then(({ data }) => {
        dispatch({
          type: SurveyActionTypes.CREATE,
          payload: data
        });
      })
      .catch((error) => {
        if (error.response) {
          // Request made and server responded
          console.log('Error: ' + error.response.data);
          console.log('Error: ' + error.response.status);
          console.log('Error: ' + error.response.headers);
        } else if ('Error: ' + error.request) {
          // The request was made but no response was received
          console.log('Error: ' + error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          alert('Error: ' + error.message);
        }
      });
  };
}

// selectors
export const getSurveysResponse = (state) => state.surveysResponse.surveysResponse;

export const getUserSurveysResponse = (state) => state.surveysResponse.surveysResponse;
