
import api from "../../services/api";


export enum MenteeActionTypes {
  FIND = "MENTEE_FIND",
  UPDATE = "MENTEE_UPDATE"
}


// export function findMenteeByUserId(authId: string, email: string){
//   return function(dispatch) {
//     console.log('ACTION LINE 11', authId, email);
//     return api.get("/mentees/profile", { 
//       params: { authId, email }
//     })
//       .then(({ data }) => {
//       // dispatch({
//       //   type: MenteeActionTypes.FIND,
//       //   payload: data
//       // });
//     });
//   };
// }

export function findMenteeByUserId(authId: string, email: string) {
  return function(dispatch) {
    return api.get("/mentees/profile", { 
      params: { authId, email }
    })
      .then(({ data }) => {
      dispatch({
        type: MenteeActionTypes.FIND,
        payload: data
      });
    });
  };
}

export function updateMentee(mentee: {id: string, title: string, linkedin: string, name: string}){
  return function (dispatch){
    return api.patch('/mentees', mentee)
    .then(({data}) => {
      dispatch({
        type: MenteeActionTypes.UPDATE,
        payload: data
      })
    })
  }
}

export const getMentee = (state) => state.mentees.mentee;