import React, { useEffect } from 'react';
import 'react-vertical-timeline-component/style.min.css';
import { Box } from '@mui/material';
import { UserStatus } from '../../enums/enums';
import { findUserProfile, getUserProfile } from '../../redux/actions/user';
import { useDispatch, useSelector } from 'react-redux';
import TimeBasedGreeting from '../../components/general/TimeBasedGreeting';
import LeadershipQuotes from './LeadershipQuotes';
import HomeInactive from './HomeInactive';
import FeaturedVideo from './FeaturedVideo';
import LeaderDashboard from './LeaderDashboard';
import UserOverview from './UserOverview';
import ResourcesGrid from "./ResourcesGrid";

interface Props {}
const WelcomePage: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const userId = localStorage.getItem('LOCELLE:USER');
  const user = useSelector(getUserProfile);

  useEffect(() => {
    if (userId) {
      dispatch(findUserProfile(userId));
    }
  }, [userId]);

  return (
    <>
      {UserStatus.Active === user?.status && (
        <>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ mr: 2, mt: -3 }}>
              <TimeBasedGreeting userName={user?.name} />
            </Box>
            <Box sx={{ ml: 2, mb: 3, mt: 3 }}>
              <LeadershipQuotes />
            </Box>

            <Box sx={{ mb: 3, mx: 2 }}>
              <UserOverview />
              <LeaderDashboard />
            </Box>
            <Box sx={{ mt: 6 }}>
              <ResourcesGrid user={user} />
            </Box>
          </Box>
        </>
      )}

      {UserStatus.Inactive === user?.status && (
        <Box sx={{ mt: 6 }}>
          <HomeInactive />
        </Box>
      )}
    </>
  );
};

export default WelcomePage;