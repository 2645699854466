import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { CoachingSessionStatus } from './coaching.enum';
import { cancelCoachingSession } from '../../redux/actions/coachingSessions';

interface Props {
  isOpen: string;
  handleClose: () => void;
  refreshSessionList: () => void;
  userId: string;
}

const CancelCoachingSessionDialog = ({ isOpen, handleClose, refreshSessionList, userId }: Props) => {
  const dispatch = useDispatch();

  const agreeCancelSession = () => {
    dispatch(cancelCoachingSession(isOpen, userId));
    handleClose();
    refreshSessionList();
  };

  return (
    <Dialog open={!!isOpen} onClose={handleClose}>
      <DialogTitle id="alert-dialog-title">Cancel the session?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography>Are you sure you want to cancel this session? </Typography>It is hard to find time that would work
          for all outside of scheduled times, so if you have to cancel and would like to reschedule, please take the
          following action:
          <Typography>
            Email us at{' '}
            {
              <a
                href="mailto:team@locelle.com?subject=Reschedule Coaching Session"
                style={{ textDecoration: 'none', color: '#241a1a' }}
              >
                team@locelle.com
              </a>
            }{' '}
            with your availability over the next 2-3 days.
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ mb: 2, mr: 2 }}>
        <Button variant="outlined" color="success" autoFocus onClick={handleClose}>
          Keep Session
        </Button>
        <Button variant="outlined" color="secondary" onClick={agreeCancelSession}>
          Confirm Cancelling
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CancelCoachingSessionDialog;
