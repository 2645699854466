import { Secret, sign } from 'jsonwebtoken';
import { readFileSync } from 'fs';

var privateKEY = 
`-----BEGIN PRIVATE KEY-----
MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCAxwoeetK/aUuK
BxouQkCaxajJtd4g7NxjxtUu1loQIAUfpOKdW+KhN5Aejojt+cZectChU6pKnNd0
bnslQbkdzRWgipUl6a7OFzsjcXqGpCgvcCYMbG4Bmg3Av0ulI8sBV8yq2ZJt1BZU
Z6uuulPldAxSDWPVz9tNqXWyvoJpF0iRMbCOdBTytwXgPWItp0edy5/+ZIlCZS6U
BymeW1anN4cNHpuoIh26DO7xM7TVZIs8deT0w7nIEFy9WmTDtlabSQ3mTTCQMYpO
fDESysyqiUj6MFshisWOIZxgWcpkte3YWxXYIQRIlx3rp69w/O8NaLK4X2mLlSh0
gwwLWld5AgMBAAECggEAYDxeg4imFgVKPkwwdIFuZjtwd81Bwrk/uhDNH8NwaryD
F7LQ52fTZUdwnGVITw9p/wsufmFX0YqEOK6XuK/09yBcGS13aG3KBJm3yDT1q8jh
fxIAXShqzSejP3e1wCOPgeFrQgJ2Oz1TsqAG3PzB1adzzZ3UDeTXrqIFIx7btkxv
jwbwdiNcEVzvne8Y9oYGSfIl7N/jxgtSlnbMRNqfku4tmp7I9/B39h7JsWbbOv6+
vBcd7CXn7J7DcnUNMb6BrcNNG2UP8nsBpk9xrFKXyB/xYxEpTsWdaCtTo+9WZJOR
0xiFBGGOzecGwlTh2L5lpTie26DP/kBvgXYbCUP5AQKBgQDHVfr1ifKZYns6YdbR
SyP38WTbDDkX3aBu6xlKaxzanbsrMzGAIPEjHAK9gYF2WJVx0TPf430byUNpqnNn
p1w5bE7fWD9ZTmo8+nKyZ8UqAr8BNdcCIE+gj418RcGh3NRLNqtSGZfR6NPiJQ/F
53bSCZmILOF4645MF9coi9cHfQKBgQClYmdS6Ph7CXQ97iQKtVtEjOlSH41KOPMe
qEtxVFOe11FeZdzdL77ng75sOquSMbz71wes5sU3R/G/YeQQOiXTWvEtI9Q+AJJB
3RsEFaGsN5kjXvkFiiTAOn9oCC35bNDgER1yzc5tlsM1ibtEX/UE03qCK3u0ahTW
1RLGexXorQKBgHrrVTbmWvgk13JX9NzY5hQ2AghlkCtcWlsrlEvko0VYMJviFKs7
JReOZU4wtwGgfH9UDxYHtRVWVcCqAEIx3Nf4JubpBx9uEGsL0YCcJuPSD7CSX8SK
4i9Tdl7ksRO8FhlPW/PjnlAtr8LrMb/LDOVWUBu9+UfUkcnwhZgWK4INAoGAKJSx
x71Uo1QZisyxWVNQ1sxAwB7v22OH/GDNSjyn3BT3aVNTU47QqBxCkGoBCXlc5urH
flyNzdfAcPaDMJwkBe2NukbNYH1hZfnsFB9+sTRfRjeaoQpZpDV2Z7+CEG7r/d8V
O8NWQLu6oJtwMQHerFV7/Q7QZt+Ux3Y3Ci7PnG0CgYEApCIKEtW/7I1sr1+hn4f1
j/qIuhzxeYoDGc/4FZdl3c9HnmRZIaXRTnTVWVHv5gVqqGc6rqGZRStGSySeoBs9
KEPeoyH5B4eY+EvyYERcfJBDVknL4NHvQAqexIxRXhRSX7/hkIX4hTtIi6paLz0g
XKLR6cxyGKX21cBI8SZOMgo=
-----END PRIVATE KEY-----`;


const appId= "vpaas-magic-cookie-26792429788c4f7db39e48dd7c73b6d4";
const kid = "vpaas-magic-cookie-26792429788c4f7db39e48dd7c73b6d4/672893";
/**
 * Function generates a JaaS JWT.
 */
 const generate = ({ id, name, email}: {
     id: string; name: string; email: string; 
   }) => {
  const now = new Date();
  const jitsi = {
    aud: 'jitsi',
    context: {
      user: {
        id,
        name,
        email: email,
        moderator: 'true'
      },
      features: {
        livestreaming: 'true',
        recording: 'true',
        transcription: 'true',
        "outbound-call": 'true'
      }
    },
    iss: 'chat',
    room: '*',
    sub: appId,
    exp: Math.round(now.setHours(now.getHours() + 3) / 1000),
    nbf: (Math.round((new Date()).getTime() / 1000) - 10)
  };
  const jwt = sign(jitsi, privateKEY, { algorithm: 'RS256', header: {alg: 'RS256', kid } });
  return jwt;
}

// export default generate;
/**
 * Generate a new JWT.
 */
//  const token = generate( {
//   id: '123456',
//   name: "my user name",
//   email: "my user email",
//   appId: "vpaas-magic-cookie-26792429788c4f7db39e48dd7c73b6d4", // Your AppID ( previously tenant )
//   kid: "vpaas-magic-cookie-26792429788c4f7db39e48dd7c73b6d4/672893"
// });

export default generate;