import React from 'react';
import QuestionsHeaderCoaching from './QuestionsHeaderCoaching';
import QuestionsBodyCoaching from './QuestionsBodyCoaching';
import { Box, Divider, Tab, Tabs } from '@mui/material';
import { Colors } from '../../enums/enums';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import QuestionsHeaderMentoring from './QuestionsHeaderMentoring';
import QuestionsBodyMentoring from '../faq/QuestionsBodyMentoring';

interface Props {}

const FAQCommon: React.FC<Props> = () => {
  const [value, setValue] = React.useState(1);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="inherit"
          variant="standard"
          sx={{
            '& .MuiTabs-indicator': { backgroundColor: Colors.MenuText },
            '& .MuiTab-root': { color: 'grey', width: '250px' },
            '& .Mui-selected': { color: Colors.Text }
          }}
        >
          <Tab sx={{ mt: 2 }} value={1} label="Coaching" icon={<HelpOutlineIcon />} />
          <Tab sx={{ mt: 2 }} value={2} label="Mentorship" icon={<HelpOutlineIcon />} />
        </Tabs>
      </Box>

      {value === 1 && (
        <>
          <QuestionsHeaderCoaching />
          <Divider style={{ marginTop: '30px' }} />
          <QuestionsBodyCoaching />
        </>
      )}
      {value === 2 && (
        <>
          <QuestionsHeaderMentoring />
          <Divider style={{ marginTop: '30px' }} />
          <QuestionsBodyMentoring />
        </>
      )}
    </>
  );
};

export default FAQCommon;
