import React, { useEffect } from 'react';
import { FormControl, FormLabel, FormHelperText, Grid } from '@material-ui/core';
import { useStyles } from '../../styles';
import { useStateMachine } from 'little-state-machine';
import { useForm, Controller } from 'react-hook-form';
import { IFormStep } from '../midCohortCoacingSurvey.interface';
import { updateAction } from '../actions';
import { FormGroup, Button, TextField, Typography, Fade } from '@mui/material';

export const Section8: React.FC<IFormStep> = ({ step, setStep }) => {
  const { state, actions } = useStateMachine({
    updateAction
  });
  const {
    handleSubmit,
    getValues,
    control,
    watch,
    register,
    formState: { errors }
  } = useForm({
    defaultValues: state.midCohortCoachingSurvey.formData as any
  });
  const classes = useStyles();
  const watchAreasImproved = watch('areasImproved');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleBack = () => {
    const partialData = getValues();
    actions.updateAction({ midCohortCoachingSurvey: { formData: partialData } } as any);
    setStep(step - 1);
  };

  const onSubmit = (data: any) => {
    console.log('onSubmit Step 8: ', data);
    actions.updateAction({ midCohortCoachingSurvey: { formData: data } } as any);
    setStep(step + 1);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Fade in={true}>
        <Grid className={classes.formContent} container spacing={5} alignItems="center" justifyContent="center">
          <Grid container item xs={12} direction="column">
            <FormControl>
              <FormLabel className={classes.formLabel} style={{ marginTop: -30 }}>
                In what areas do you feel you have improved in? *
                <Typography sx={{ mt: 1 }}>These are the session topics you would have covered. </Typography>
              </FormLabel>
              <FormGroup>
                {[
                  'Core Values and Uncovering Personal Leadership Style',
                  'Developing Confidence and Influence at Work',
                  'Building Influence with Peers and Teams',
                  'Leading Your Manager',
                  'Other'
                ].map((optionValue, index) => (
                  <Grid item xs={12} key={index}>
                    <FormLabel key={optionValue} className={classes.checkbox}>
                      <input
                        {...register('areasImproved', { required: true })}
                        type="checkbox"
                        value={optionValue}
                        name="areasImproved"
                        style={{ margin: '20px 30px 20px 30px', transform: 'scale(1.5)', accentColor: 'green' }}
                      />
                      <span style={{ fontSize: '18px', color: 'grey', marginTop: '5px' }}>{optionValue}</span>
                    </FormLabel>
                  </Grid>
                ))}
              </FormGroup>
              {errors.areasImproved?.type === 'required' && (
                <FormHelperText style={{ color: 'red' }}>Required</FormHelperText>
              )}
              <FormHelperText>{errors?.areasImproved?.message}</FormHelperText>
            </FormControl>
            {watchAreasImproved && watchAreasImproved.includes('Other') && (
              <Grid item xs={8} style={{ marginLeft: '20px' }}>
                <br />
                <Controller
                  render={({ field }) => (
                    <TextField
                      sx={{ position: 'absolute', width: '500px' }}
                      {...field}
                      placeholder="Type your answer here..."
                      required
                      color="success"
                      variant="outlined"
                    />
                  )}
                  name="otherAreasImproved"
                  control={control}
                />
              </Grid>
            )}
          </Grid>

          <Grid item className={classes.buttonContainter}>
            <Button className={classes.button} variant="outlined" color="success" onClick={() => handleBack()}>
              BACK
            </Button>
          </Grid>

          <Grid item className={classes.buttonContainter}>
            <Button className={classes.button} variant="contained" color="success" type="submit">
              NEXT
            </Button>
          </Grid>
        </Grid>
      </Fade>
    </form>
  );
};
