import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ThemeProvider, Grid, Paper } from '@material-ui/core';
import { theme, useStyles } from '.././styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import BestHumanLogo from '../../../../assets/besthuman.png';
import { StateMachineProvider, createStore } from 'little-state-machine';
import { fetchClientSurveys, getSurveys } from '../../../../redux/actions/surveys';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import formSections from './formSections';
import allFormFields from './onboardingAllFormFields';
import { Box, Typography, LinearProgress } from '@mui/material';
import { Colors, SurveyTargetType, SurveyType } from '../../../../enums/enums';

interface FormStep {
  step: number;
  component: React.FC<any>;
}

createStore(
  {
    formDetails: allFormFields,
    feedbackDetails: allFormFields
  },
  { name: '', middleWares: [] }
);

export default function OnBoardingAllForm() {
  const classes = useStyles();
  const { code } = useParams<{ code: string }>();
  const stepsCount = formSections.length - 1;
  const [step, setStep] = useState(1);

  const dispatch = useDispatch();
  const surveys: any[] = useSelector(getSurveys);

  useEffect(() => {
    dispatch(fetchClientSurveys(code));
  }, [dispatch, code]);

  const clientOnboardingSurvey = surveys.find(
    (s) => s.type === SurveyType.Onboarding && s.target === SurveyTargetType.All
  );

  return (
    <StateMachineProvider>
      {!clientOnboardingSurvey ? (
        <div style={{ marginLeft: '50%', marginTop: '30%' }}>
          <Typography>Loading</Typography>
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className={classes.pageContent}>
            <Paper>
              <Grid className={classes.formContainer} container spacing={5} alignItems="center" justifyContent="center">
                <Grid style={{ paddingTop: 10 }} item>
                  <img src={BestHumanLogo} alt="logo" width="200px" />
                </Grid>

                {step <= stepsCount && (
                  <Grid className={classes.headerBlock} item xs={12}>
                    <Typography sx={{ color: Colors.Text, mb: 2 }} variant="h5" align="center" gutterBottom>
                      Crafting Your Unique Experience at BestHuman
                    </Typography>
                    {step === 1 && (
                      <Box>
                        <Box
                          sx={{
                            backgroundColor: Colors.BackgroundDrawer,
                            width: '667px',
                            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                            mb: 2
                          }}
                        >
                          <Typography
                            sx={{ pt: 3, mb: 3, color: Colors.MenuText, fontSize: '1.2rem' }}
                            textAlign="center"
                          >
                            <b>Welcome to BestHuman! </b>
                          </Typography>
                          <Typography textAlign="center" sx={{ pb: 3, color: Colors.TextElevated }}>
                            To personalize your experience, we require some information about you and your professional
                            background. All personal and demographic information is kept confidential, and is used to
                            optimize your learning journey and improve our platform and programs.
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Grid container spacing={5} alignItems="center" justifyContent="center">
                    {formSections.map(
                      (formSection: FormStep, index: number) =>
                        formSection.step === step && (
                          <formSection.component
                            key={index}
                            step={step}
                            setStep={setStep}
                            survey={clientOnboardingSurvey}
                          />
                        )
                    )}

                    {step <= stepsCount && (
                      <Grid item xs={12}>
                        <Typography component="h6" align="right">
                          Page {step} of {stepsCount}
                          <LinearProgress variant="determinate" color="success" value={(step / stepsCount) * 100} />
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </div>
        </ThemeProvider>
      )}
    </StateMachineProvider>
  );
}