import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import generate from '../../services/meeting/authMeeting';
import { JaaSMeeting } from '@jitsi/react-sdk';
import { JITSI_APP_ID } from '../../constants/constants';

interface Props {
  roomOrganizerName: string;
  sessionId: string;
  handleClose: () => void;
  hidePreMeetingOptions: () => void;
  sessionTopic: string;
}

export default function CoachingJitsiMeetingRoom({
  roomOrganizerName,
  sessionId,
  handleClose,
  hidePreMeetingOptions,
  sessionTopic
}: Props) {
  const { user } = useAuth0();
  const { sub, name, email } = user;
  const sessionIdPartial = sessionId?.slice(0, 6);

  const roomName =`${roomOrganizerName} ${sessionIdPartial}`;
  const jwt = generate({ id: sub, name, email });

  return (
    <div style={{ height: '100%' }}>
      <JaaSMeeting
        appId={JITSI_APP_ID}
        roomName={roomName}
        jwt={jwt}
        getIFrameRef={(iframe) => {
          iframe.style.height = '100%';
        }}
        userInfo={{
          displayName: name,
          email: email
        }}
        configOverwrite={
          {
            // innerHeight: '100%',
            // disableThirdPartyRequests: true,
            // disableLocalVideoFlip: true,
            // backgroundAlpha: 0.5
          }
        }
        interfaceConfigOverwrite={
          {
            // VIDEO_LAYOUT_FIT: 'nocrop',
            // MOBILE_APP_PROMO: false,
            // TILE_VIEW_MAX_COLUMNS: 4
          }
        }
        onApiReady={() => {
          console.log('User Joined ', user.name);
          hidePreMeetingOptions();
        }}
        onReadyToClose={() => handleClose()}
      />
    </div>
  );
}
