import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { findInternalUser, getInternalUser } from '../../redux/actions/user';
import UserProfile from './UserProfile';

const Profile: React.FC = () => {
  const dispatch = useDispatch();
  const { user, isLoading } = useAuth0();
  const internalUser = useSelector(getInternalUser);

  const { sub, email } = user;

  useEffect(() => {
    dispatch(findInternalUser(sub, email));
  }, []);

  return <>{isLoading ? <CircularProgress color="inherit" /> : <UserProfile internalUser={internalUser} />}</>;
};

export default Profile;
