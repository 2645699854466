import React, { useEffect } from 'react';
import { FormControl, FormLabel, FormHelperText, Grid, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { useStyles } from '../../styles';
import { useStateMachine } from 'little-state-machine';
import { useForm, Controller } from 'react-hook-form';
import { IFormStep } from '../midCohortCoacingSurvey.interface';
import { updateAction } from '../actions';
import { Button, Fade } from '@mui/material';

export const Section2: React.FC<IFormStep> = ({ step, setStep }) => {
  const { state, actions } = useStateMachine({
    updateAction
  });
  const {
    handleSubmit,
    getValues,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: state.midCohortCoachingSurvey.formData as any
  });
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleBack = () => {
    const partialData = getValues();
    actions.updateAction({ midCohortCoachingSurvey: { formData: partialData } } as any);
    setStep(step - 1);
  };

  const onSubmit = (data: any) => {
    console.log('onSubmit Step 2: ', data);
    actions.updateAction({ midCohortCoachingSurvey: { formData: data } } as any);
    setStep(step + 1);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Fade in={true}>
        <Grid className={classes.formContent} container spacing={5} alignItems="center" justifyContent="center">
          <Grid container item xs={12} direction="column">
            <FormControl>
              <FormLabel className={classes.formLabel}>Have you found the assigned readings helpful? * </FormLabel>
              <Controller
                render={({ field }) => (
                  <RadioGroup style={{ marginLeft: 50 }} {...field}>
                    {['Yes', 'No'].map((aspect, index) => (
                      <FormControlLabel
                        key={index}
                        value={aspect}
                        control={<Radio color="primary" style={{ marginRight: 10 }} />}
                        label={aspect}
                        style={{ marginBottom: 8, scale: '110%' }}
                      />
                    ))}
                  </RadioGroup>
                )}
                name="assignedReadings"
                control={control}
                rules={{ required: true }}
              />
              {errors?.assignedReadings?.type === 'required' && (
                <FormHelperText className={classes.errorText}>Required</FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item className={classes.buttonContainter}>
            <Button className={classes.button} variant="outlined" color="success" onClick={() => handleBack()}>
              BACK
            </Button>
          </Grid>

          <Grid item className={classes.buttonContainter}>
            <Button className={classes.button} variant="contained" color="success" type="submit">
              NEXT
            </Button>
          </Grid>
        </Grid>
      </Fade>
    </form>
  );
};
