import { AnyAction } from "redux";
import { MatchesActionTypes } from "../actions/matches";

  const initialState = {
    macth: [],
  }

  export function MatchesReducer(state = initialState, action: AnyAction){

    if (!state) {
      return initialState;
    }

    switch(action.type){

      case MatchesActionTypes.FIND: 
        return {
          ...state,
          match: action.payload
        }
        
      default:
        return state;
    }

    
  }

  