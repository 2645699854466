import api from '../../services/api';

export enum SurveyActionTypes {
  FETCH = 'SURVEY_FETCH',
  FETCH_BY_CLIENT = 'FETCH_BY_CLIENT'
}

export function fetchCurrentSurveys() {
  return function (dispatch: (arg0: { type: SurveyActionTypes; payload: any }) => void) {
    return api.get('/surveys').then(({ data }) => {
      dispatch({
        type: SurveyActionTypes.FETCH,
        payload: data
      });
    });
  };
}

export function fetchClientSurveys(clientCode: string) {
  return function (dispatch: (arg0: { type: SurveyActionTypes; payload: any }) => void) {
    return api.get(`/surveys/code/${clientCode}`).then(({ data }) => {
      dispatch({
        type: SurveyActionTypes.FETCH_BY_CLIENT,
        payload: data
      });
    });
  };
}

// selectors
export const getSurveys = (state: { surveys: { surveys: any[] } }) => state.surveys.surveys;
