import React from 'react';
import { FormControl, FormLabel, Grid } from '@material-ui/core';
import { useStyles } from '../../styles';
import { useStateMachine } from 'little-state-machine';
import { useForm, Controller } from 'react-hook-form';
import { TextField, Fade, Button } from '@mui/material';
import { IFormStep } from '../midCohortCoacingSurvey.interface';
import { updateAction } from '../actions';
import api from '../../../../services/api';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { TaskType } from '../../../../enums/enums';

interface IFormStepExtended extends IFormStep {
  userId: string;
  taskId: string;
  groupId: string;
}

export const Section13: React.FC<IFormStepExtended> = ({ step, setStep, userId, taskId, groupId }) => {
  const { state, actions } = useStateMachine({
    updateAction
  });

  const history = useHistory();

  const {
    handleSubmit,
    getValues,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: state.midCohortCoachingSurvey.formData as any
  });

  const classes = useStyles();

  const handleBack = () => {
    const partialData = getValues();
    actions.updateAction({ midCohortCoachingSurvey: { formData: partialData } } as any);
    setStep(step - 1);
  };

  const handleComplete = (data): void => {
    const formData = data;
    const payload = {
      userId: userId,
      taskId: taskId,
      groupId: groupId,
      taskName: TaskType.MidCohortGroupAssessment,
      formResponse: formData
    };
    const url = '/tasks/response';
    api
      .post(url, payload)
      .then(() => toast.success('Thank you for submitting your Mid-Cohort Assessment'))
      .catch(() => toast.error('Error submitting Mid-Cohort Assessment'))
      .finally(() => history.push('/tasks'));

    actions.updateAction({
      midCohortCoachingSurvey: {
        formData: null
      }
    } as any);
  };

  const onSubmit = (data: any): void => {
    console.log('Mid-Cohort FormData: ', data);
    actions.updateAction({
      midCohortCoachingSurvey: {
        formData: {
          ...data
        }
      }
    } as any);

    handleComplete(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Fade in={true}>
        <Grid className={classes.formContent} container spacing={5} alignItems="center" justifyContent="center">
          <Grid container item xs={12} direction="column">
            <FormControl>
              <FormLabel className={classes.formLabel}>Do you have any feedback on the Portal?</FormLabel>
              <Controller
                render={({ field }) => (
                  <TextField
                    multiline
                    rows={4}
                    placeholder="Type your answer here..."
                    fullWidth
                    color="success"
                    {...field}
                    sx={{ mb: 4 }}
                  />
                )}
                name="portalImprovement"
                control={control}
              />
            </FormControl>
          </Grid>

          <Grid item className={classes.buttonContainter}>
            <Button className={classes.button} variant="outlined" color="success" onClick={() => handleBack()}>
              BACK
            </Button>
          </Grid>

          <Grid item className={classes.buttonContainter}>
            <Button className={classes.button} variant="contained" color="success" type="submit">
              SUBMIT
            </Button>
          </Grid>
        </Grid>
      </Fade>
    </form>
  );
};
