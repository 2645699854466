import React, { useEffect } from 'react';
import { FormControl, FormLabel, FormHelperText, Grid } from '@material-ui/core';
import { useStyles } from '../../styles';
import { useStateMachine } from 'little-state-machine';
import { useForm, Controller } from 'react-hook-form';
import { IFormStep } from '../midCohortCoacingSurvey.interface';
import { updateAction } from '../actions';
import { TextField, Button, Fade } from '@mui/material';

export const Section7: React.FC<IFormStep> = ({ step, setStep }) => {
  const { state, actions } = useStateMachine({
    updateAction
  });
  const {
    handleSubmit,
    getValues,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: state.midCohortCoachingSurvey.formData as any
  });
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleBack = () => {
    const partialData = getValues();
    actions.updateAction({ midCohortCoachingSurvey: { formData: partialData } } as any);
    setStep(step - 1);
  };

  const onSubmit = (data: any) => {
    console.log('onSubmit Step 7: ', data);
    actions.updateAction({ midCohortCoachingSurvey: { formData: data } } as any);
    setStep(step + 1);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Fade in={true}>
        <Grid className={classes.formContent} container spacing={5} alignItems="center" justifyContent="center">
          <Grid container item xs={12} direction="column">
            <FormControl>
              <FormLabel className={classes.formLabel}>
                What is a thing you have benefited from and/or enjoyed the most so far? *
              </FormLabel>
              <Controller
                render={({ field }) => (
                  <TextField
                    multiline
                    rows={4}
                    placeholder="Type your answer here..."
                    fullWidth
                    color="success"
                    {...field}
                  />
                )}
                name="benefits"
                control={control}
                rules={{ required: true }}
              />
              {errors?.benefits?.type === 'required' && (
                <FormHelperText className={classes.errorText}>Required</FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item className={classes.buttonContainter}>
            <Button
              className={classes.button}
              style={{ marginTop: -50 }}
              variant="outlined"
              color="success"
              onClick={() => handleBack()}
            >
              BACK
            </Button>
          </Grid>

          <Grid item className={classes.buttonContainter}>
            <Button
              className={classes.button}
              style={{ marginTop: -50 }}
              variant="contained"
              color="success"
              type="submit"
            >
              NEXT
            </Button>
          </Grid>
        </Grid>
      </Fade>
    </form>
  );
};
