import api from '../../services/api';
import { toast } from 'react-toastify';

export enum UserActionTypes {
  FIND_INTERNAL_USER = 'FIND_INTERNAL_USER',
  FIND_USER_PROFILE = 'USER_PROFILE',
  UPDATE = 'UPDATE_PROFILE',
  FETCH_SESSION_LEADER = 'FETCH_SESSION_LEADER'
}

export function findUserProfile(userId: string) {
  return function (dispatch) {
    return api.get('/users/profile', { params: { userId } }).then(({ data }) => {
      dispatch({
        type: UserActionTypes.FIND_USER_PROFILE,
        payload: data
      });
    });
  };
}

export function findInternalUser(authId: string, email: string) {
  return function (dispatch) {
    return api.get('/users', { params: { authId, email } }).then(({ data }) => {
      localStorage.setItem('LOCELLE:USER', data.id);
      localStorage.setItem('LOCELLE:USER_TYPE', data.type);
      dispatch({
        type: UserActionTypes.FIND_INTERNAL_USER,
        payload: data
      });
    });
  };
}

export function findSessionLeaderByMeetingId(meetingId: string) {
  return function (dispatch: (arg0: { type: UserActionTypes; payload: any }) => void) {
    return api.get('/users/meeting', { params: { meetingId } }).then(({ data }) => {
      dispatch({
        type: UserActionTypes.FETCH_SESSION_LEADER,
        payload: data
      });
    });
  };
}

export function updateUser(user: { userId: string; title: string; linkedin: string; name: string; email2: string }) {
  return function (dispatch) {
    return api
      .patch('/users', user)
      .then(({ data }) => {
        dispatch({
          type: UserActionTypes.UPDATE,
          payload: data
        });
        toast.success('Successfully updated profile');
      })
      .catch((error) => toast.error('Error updating profile'));
  };
}

export const getInternalUser = (state) => state.users.users;
export const getUserProfile = (state) => state.users.profile;
export const getSessionLeader = (state) => state.users.sessionLeader;
