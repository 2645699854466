import React, { useEffect } from 'react';
import { FormControl, FormLabel, FormHelperText, Grid } from '@material-ui/core';
import { useStyles } from '../../styles';
import { useStateMachine } from 'little-state-machine';
import { useForm, Controller } from 'react-hook-form';
import { TextField, Fade, Button, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { IFormStep } from '../oneOnOneFinalAssessment.interface';
import { updateAction } from './oneOnOneFinalActions';

export const Section8: React.FC<IFormStep> = ({ step, setStep }) => {
  const { state, actions } = useStateMachine({
    updateAction
  });

  const {
    handleSubmit,
    getValues,
    control,
    watch,
    formState: { errors }
  } = useForm({
    defaultValues: state.oneOnOneFinalAssessment.formData as any
  });
  const classes = useStyles();
  const watchReferProgram = watch('referProgram');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleBack = () => {
    const partialData = getValues();
    actions.updateAction({ oneOnOneFinalAssessment: { formData: partialData } } as any);
    setStep(step - 1);
  };

  const onSubmit = (data: any) => {
    console.log('onSubmit Step 8: ', data);
    actions.updateAction({ oneOnOneFinalAssessment: { formData: data } } as any);
    setStep(step + 1);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Fade in={true}>
        <Grid className={classes.formContent} container spacing={5} alignItems="center" justifyContent="center">
          <Grid container item xs={12} direction="column">
            <FormControl>
              <FormLabel className={classes.formLabel}>Would you refer this program to your colleagues *</FormLabel>
              <Controller
                render={({ field }) => (
                  <RadioGroup style={{ marginLeft: 50 }} {...field}>
                    {['Yes', 'No', 'Other'].map((aspect, index) => (
                      <FormControlLabel
                        key={index}
                        value={aspect}
                        control={<Radio color="success" style={{ marginRight: 10 }} />}
                        label={aspect}
                        style={{ marginBottom: 8, scale: '110%' }}
                      />
                    ))}
                  </RadioGroup>
                )}
                name="referProgram"
                control={control}
                rules={{ required: true }}
              />
              {errors?.referProgram?.type === 'required' && (
                <FormHelperText className={classes.errorText}>Required</FormHelperText>
              )}
            </FormControl>
            {watchReferProgram && watchReferProgram.includes('Other') && (
              <Grid item xs={8} style={{ marginLeft: '20px' }}>
                <br />
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="Type your answer here..."
                      required
                      color="success"
                      fullWidth
                      variant="outlined"
                    />
                  )}
                  name="otherReferProgram"
                  control={control}
                />
              </Grid>
            )}
          </Grid>

          <Grid item className={classes.buttonContainter}>
            <Button className={classes.button} variant="outlined" color="success" onClick={() => handleBack()}>
              BACK
            </Button>
          </Grid>

          <Grid item className={classes.buttonContainter}>
            <Button className={classes.button} variant="contained" color="success" type="submit">
              NEXT
            </Button>
          </Grid>
        </Grid>
      </Fade>
    </form>
  );
};
