import React, { useEffect } from 'react';
import { FormControl, FormLabel, FormHelperText, Grid } from '@material-ui/core';
import { useStyles } from '../../styles';
import { useStateMachine } from 'little-state-machine';
import { useForm, Controller } from 'react-hook-form';
import { IFormStep } from '../finalSessionCoacingSurvey.interface';
import { updateAction } from '../actions';
import { TextField, Fade, FormGroup, Button } from '@mui/material';

export const Section7: React.FC<IFormStep> = ({ step, setStep }) => {
  const { state, actions } = useStateMachine({
    updateAction
  });
  const {
    handleSubmit,
    getValues,
    control,
    register,
    watch,
    formState: { errors }
  } = useForm({
    defaultValues: state.finalSessionCoachingSurvey.formData as any
  });
  const classes = useStyles();
  const watchGainedInsights = watch('gainedInsights');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleBack = () => {
    const partialData = getValues();
    actions.updateAction({ finalSessionCoachingSurvey: { formData: partialData } } as any);
    setStep(step - 1);
  };

  const onSubmit = (data: any) => {
    console.log('onSubmit Step 7: ', data);
    actions.updateAction({ finalSessionCoachingSurvey: { formData: data } } as any);
    setStep(step + 1);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Fade in={true}>
        <Grid className={classes.formContent} container spacing={5} alignItems="center" justifyContent="center">
          <Grid container item xs={12} direction="column">
            <FormControl>
              <FormLabel className={classes.formLabel} style={{ marginTop: -50 }}>
                In what areas do you feel you have learned something new or have gained insights to improve? *
              </FormLabel>
              <FormGroup style={{ marginTop: -20 }}>
                {[
                  'Giving and getting feedback',
                  'Conflict resolution',
                  'Personal Leadership Style',
                  'Better delegation',
                  'Effective Communication',
                  'Better understanding of leadership topics',
                  'Leading my team',
                  'Other'
                ].map((optionValue, index) => (
                  <Grid item xs={12} key={index}>
                    <FormLabel key={optionValue} className={classes.checkbox}>
                      <input
                        {...register('gainedInsights', { required: true })}
                        type="checkbox"
                        value={optionValue}
                        name="gainedInsights"
                        style={{ margin: '20px 30px 10px 30px', transform: 'scale(1.5)' }}
                      />
                      <span style={{ fontSize: '18px', color: 'grey', marginTop: '5px' }}>{optionValue}</span>
                    </FormLabel>
                  </Grid>
                ))}
              </FormGroup>
              {errors.gainedInsights?.type === 'required' && (
                <FormHelperText style={{ color: 'red' }}>Required</FormHelperText>
              )}
              <FormHelperText>{errors?.gainedInsights?.message}</FormHelperText>
            </FormControl>
            {watchGainedInsights && watchGainedInsights.includes('Other') && (
              <Grid item xs={8} style={{ marginLeft: '20px' }}>
                <br />
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      sx={{ position: 'absolute', width: '500px', mt: -1 }}
                      placeholder="Type your answer here..."
                      required
                      fullWidth
                      variant="outlined"
                    />
                  )}
                  name="otherGainedInsights"
                  control={control}
                />
              </Grid>
            )}
          </Grid>

          <Grid item className={classes.buttonContainter}>
            <Button
              className={classes.button}
              style={{ marginTop: -10 }}
              variant="outlined"
              color="success"
              onClick={() => handleBack()}
            >
              BACK
            </Button>
          </Grid>

          <Grid item className={classes.buttonContainter}>
            <Button
              className={classes.button}
              style={{ marginTop: -10 }}
              variant="contained"
              color="success"
              type="submit"
            >
              NEXT
            </Button>
          </Grid>
        </Grid>
      </Fade>
    </form>
  );
};
