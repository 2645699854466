import React, { useEffect, useState } from 'react';
import { ThemeProvider, Grid, Paper } from '@material-ui/core';
import { theme, useStyles } from '../styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { StateMachineProvider, createStore } from 'little-state-machine';
import { Button, Typography, LinearProgress } from '@mui/material';
import { DEFAULT_VALUE } from './midCohortCoacingSurvey.constant';
import { IFormStep } from './midCohortCoacingSurvey.interface';
import HomeIcon from '@mui/icons-material/Home';
import sections from './sections';
import { useHistory, useParams } from 'react-router-dom';

createStore(
  {
    midCohortCoachingSurvey: {
      formData: DEFAULT_VALUE
    }
  },
  { name: '', middleWares: [] }
);

export default function MidCohortCoachingSurvey() {
  const history = useHistory();
  const { userId } = useParams<{ userId: string }>();
  const { taskId } = useParams<{ taskId: string }>();
  const { groupId } = useParams<{ groupId: string }>();

  const classes = useStyles();
  const [step, setStep] = useState(1);
  const stepsCount = sections.length;

  useEffect(() => {}, []);

  return (
    <StateMachineProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className={classes.pageContent}>
          <Paper elevation={3}>
            <Button
              startIcon={<HomeIcon />}
              sx={{
                float: 'right',
                backgroundColor: '#9f9595',
                color: '#FFFFFF',
                mt: 2,
                mr: 2,
                '&:hover': {
                  backgroundColor: '#7c7272'
                }
              }}
              onClick={() => {
                history.push(`/tasks`);
              }}
            >
              Return to Portal
            </Button>
            <Grid className={classes.formContainer} container spacing={5} alignItems="center" justifyContent="center">
              {step <= stepsCount + 1 && (
                <Grid className={classes.headerBlock} item xs={12}>
                  <Typography component="h1" gutterBottom sx={{ color: 'grey', fontSize: '2rem', marginLeft: -13 }}>
                    Mid-Cohort Assessment Survey
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                <Grid container spacing={5} alignItems="center" justifyContent="center">
                  {sections.map(
                    (section: IFormStep, index: number) =>
                      section.step === step && (
                        <section.component
                          key={index}
                          step={step}
                          setStep={setStep}
                          userId={userId}
                          taskId={taskId}
                          groupId={groupId}
                        />
                      )
                  )}
                  {step <= stepsCount && (
                    <Grid item xs={12}>
                      <LinearProgress
                        style={{ height: '6px', marginTop: '10px' }}
                        variant="determinate"
                        color="success"
                        value={(step / stepsCount) * 100}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </div>
      </ThemeProvider>
    </StateMachineProvider>
  );
}
