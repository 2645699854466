import React from 'react';
import LogoBestHuman from '../../assets/besthuman.png';
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import {Box} from "@mui/material";

export default function Logo() {
  let location = useLocation();
  const useStyles = makeStyles({});

  console.log('Location', location.pathname);
  const classes = useStyles();
  const parts = location.pathname.split('/');
  const lastSegment = parts.pop() || parts.pop(); // handle potential trailing slash

  if (lastSegment === 'all') {
    return null;
  }

  return (
      <Box sx={{ position: 'absolute'}}>
      <Link to={`/`}>
      <img
        src={LogoBestHuman}
        width="25%"
        alt="Logo BestHuman"
        style={{ cursor: 'pointer', marginLeft: '120px', marginTop: '20px' }}
      />
    </Link>
      </Box>
  );
}
