import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogTitle, Stack, Typography } from '@mui/material';
import { Colors } from '../../enums/enums';
import DialogContent from '@mui/material/DialogContent';
import { IFileObjectDTO } from '../../models/fileObjects';
import { IUserProfile } from '../../redux/types';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@material-ui/icons/Close';

interface Props {
  isOpen: boolean;
  facilitatorProfile: IFileObjectDTO;
  leader: IUserProfile;
  handleClose: () => void;
}

const LeaderProfileCard = (props: Props) => {
  const { facilitatorProfile, leader, isOpen, handleClose } = props;

  return (
    <>
      <Dialog maxWidth="lg" open={isOpen} onClose={handleClose}>
        <DialogTitle
          sx={{
            position: 'sticky',
            minHeight: '55px',
            boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
            backgroundColor: Colors.BackgroundDrawer,
            color: Colors.TextElevated,
            fontSize: '1.5rem',
            textAlign: 'center'
          }}
        >
          {leader?.name}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Stack direction="row" spacing={2}>
            <img
              src={facilitatorProfile?.azureUrl}
              style={{
                width: '42%',
                height: 'auto',
                boxShadow:
                  'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                borderRadius: 5,
                marginTop: '15px',
                marginBottom: '10px',
                marginLeft: '20px'
              }}
            />
            <Box>
              <Box sx={{ mx: 5, mt: 5 }}>
                {/*doing it through html, so that it preserves the breaks set by admin*/}
                <Typography
                  sx={{ color: Colors.TextElevated }}
                  variant="body1"
                  dangerouslySetInnerHTML={{ __html: facilitatorProfile?.title }}
                />
              </Box>
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ backgroundColor: Colors.BackgroundDrawer }}>
          {leader?.linkedin && (
            <Button
              sx={{
                backgroundColor: Colors.TextElevatedLighter,
                border: 0,
                color: 'white',
                width: '200px',
                height: '30px',
                my: 1,
                mr: 2
              }}
              variant="contained"
              color="success"
              href={leader?.linkedin}
              target="_blank"
            >
              View LinkedIn Profile
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LeaderProfileCard;