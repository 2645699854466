import { makeStyles, createTheme } from '@material-ui/core/styles';
import {Colors} from "../../enums/enums";

const theme = createTheme({
  palette: {
    primary: {
      main: Colors.ButtonGreen
    },
    background: {
      default: Colors.BackgroundMain
    }
  }
});

const useStyles = makeStyles((theme) => ({
  pageContent: {
    float: 'left',
    marginLeft: 100,
    marginTop: 10,
    marginBottom: 60,
    maxWidth: 900,
    color: Colors.TextElevated,

  },
  formContainer: {
    height: '800px',
    padding: '15%',
    paddingTop: 20,
    marginBottom: 20,
    color: Colors.TextElevated,

  },
  formContent: {
    paddingTop: '60px',
    paddingLeft: '20px',
    paddingRight: '20px',
    color: Colors.TextElevated,
    backgroundColor: Colors.BackgroundMainLighter,
  },
  formLabel: {
    paddingBottom: 30,
    color: Colors.TextElevated,
    fontSize: '1.5rem',
    lineHeight: '1.4em',
    '&.Mui-focused': {
      color: Colors.TextElevated,
    }
  },
  checkboxLabel: {
    color: '#3b3b3b'
  },
  checkboxContainer: {
    marginBottom: 15,
    marginTop: 5,

  },
  checkbox: {
    marginRight: 20,
    '&.checked': {
      color: Colors.ButtonGreen,
    }
  },
  horizontalRadioGroup: {
    marginLeft: '-20px'
  },
  errorText: {
    color: 'red',
    marginTop: 10
  },
  headerBlock: {
    marginBottom: 10
  },
  buttonContainter: {
    marginTop: 50,
    display: 'inline'
  },
  button: {
    padding: '10px 30px',
    scale: 1.2,
    borderRadius: 5
  },
  logo: {
    width: '85px'
  }
}));

export { useStyles, theme };
