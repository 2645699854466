import api from '../../services/api';
import { handleCaughtApiError } from '../../utils/basic.utils';

export enum SessionActionTypes {
  FIND = 'SESSION_FIND',
  UPDATE = 'SESSION_UPDATE'
}

export function findSessionsByMatchId(matchId: string) {
  const userType = localStorage.getItem('LOCELLE:USER_TYPE');
  return function (dispatch) {
    return api
      .get('/sessions/match', {
        params: { matchId, userType }
      })
      .then(({ data }) => {
        dispatch({
          type: SessionActionTypes.FIND,
          payload: data
        });
      });
  };
}

export function findSessionsByAuthId(authId: string) {
  const userType = localStorage.getItem('LOCELLE:USER_TYPE');
  return function (dispatch) {
    return api.get(`/sessions/${authId}`, { params: { userType } }).then(({ data }) => {
      dispatch({
        type: SessionActionTypes.FIND,
        payload: data
      });
    });
  };
}

export function updateSession(session) {
  const userType = localStorage.getItem('LOCELLE:USER_TYPE');
  return function (dispatch) {
    return api
      .patch('/sessions', session, { params: { userType } })
      .then(({ data }) => {
        dispatch({
          type: SessionActionTypes.UPDATE,
          payload: data
        });
      })
      .catch(handleCaughtApiError);
  };
}

// export const getMentee = (state) => state.mentees.mentee;

export const getMenteeSessions = (state) => state.sessions.sessions;
export const getUserSessions = (state) => state.sessions.sessions;
