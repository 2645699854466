import React, { useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import { useAuth0 } from '@auth0/auth0-react';
import '../../css/Login.css';
import ListItemIcon from '@mui/material/ListItemIcon';
import Logout from '@mui/icons-material/Logout';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Link } from 'react-router-dom';
import { ListItemAvatar, ListItemText, Typography, Divider, MenuItem, Menu } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Colors } from '../../enums/enums';
import { useDispatch, useSelector } from 'react-redux';
import { findUserProfile, getUserProfile } from '../../redux/actions/user';
import PersonIcon from '@mui/icons-material/Person';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    menuButton: {
      marginRight: theme.spacing(2)
    },
    title: {
      flexGrow: 1
    },

    bar: {
      backgroundImage: 'linear-gradient(to top, #fefefe, #ffffff)'
    },
    appBar: {
      [theme.breakpoints.up('sm')]: {
        width: '100%'
        // width: `calc(100% - ${drawerWidth}px)`,
        // marginLeft: drawerWidth,
      },
      color: 'black',
      position: 'absolute',
      height: '30px'
    },
    logo: {
      marginTop: '10px',
      width: '7%'
    }
  })
);

export default function NavBar() {
  const dispatch = useDispatch();

  const classes = useStyles();
  const { loginWithRedirect, logout, user } = useAuth0();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const open = Boolean(anchorEl);
  const userId = localStorage.getItem('LOCELLE:USER');
  const userProfile = useSelector(getUserProfile);

  useEffect(() => {
    if (userId) {
      dispatch(findUserProfile(userId));
    }
  }, [userId]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const userFirstName = userProfile?.name?.split(' ')[0];
  const userLastName = userProfile?.name?.split(' ')[1];
  const userInitials = userFirstName?.split('')[0] + userLastName?.split('')[0];

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderProfileMenu = (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 18,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0
          }
        }
      }}
      // transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <Link to={`/profile`} style={{ textDecoration: 'none' }}>
        <MenuItem sx={{ mr: 2 }}>
          <ListItemAvatar sx={{ ml: 2 }}>
            <AccountCircleIcon color="success" />
          </ListItemAvatar>
          <ListItemText
            primary={<Typography sx={{ color: 'black' }}>Profile</Typography>}
            secondary={
              <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.primary">
                {user?.email}
              </Typography>
            }
          />
        </MenuItem>
        <Divider sx={{ my: 2 }} />
      </Link>

      <MenuItem onClick={() => logout()} sx={{ ml: 2, mb: 1 }}>
        <ListItemIcon>
          <Logout fontSize="small" />
        </ListItemIcon>
        <Typography sx={{ ml: 3 }}>Logout</Typography>
      </MenuItem>
    </Menu>
  );

  const login = () => {
    loginWithRedirect();
  };

  return (
    <div className={classes.appBar}>
      <Toolbar>
        <Typography variant="h6" className={classes.title}></Typography>
        {!user ? (
          <Button style={{ color: 'green', fontWeight: 'bold' }} color="inherit" onClick={login}>
            Log In
          </Button>
        ) : (
          <>
            <Tooltip title="Account settings">
              <IconButton
                onClick={handleClick}
                sx={{ ml: 2 }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              >
                <Avatar sx={{ width: 40, height: 40, backgroundColor: Colors.TextElevated }}>
                  {userInitials ? userInitials : <PersonIcon />}
                </Avatar>
                <KeyboardArrowDownIcon />
              </IconButton>
            </Tooltip>
          </>
        )}
        {renderProfileMenu}
      </Toolbar>
    </div>
  );
}