import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Auth0Provider } from "@auth0/auth0-react";
import  env  from './utils/env';
import store from "./redux/store/index";
import { Provider } from "react-redux";

ReactDOM.render(
  <Auth0Provider
  domain={env.DOMAIN}
  clientId={env.CLIENTID}
  redirectUri={window.location.origin}
  >
  <Provider store={store}>
    <App />
  </Provider>
  </Auth0Provider>,
  document.getElementById('root')
);
