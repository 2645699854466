export enum FileObjectType {
  SessionContent = 'sessionContent',
  LearningTool = 'learningTool',
  Facilitator = 'facilitator'
}

export enum FileObjectStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export interface IFileObjectDTO {
  id: string;
  filename: string;
  mimeType: string;
  type: FileObjectType;
  status?: FileObjectStatus;
  size: number;
  azureUrl: string;
  createdAt?: Date;
  updatedAt: Date;
  thumbnailUrl?: string;
  thumbnailName?: string;
  title?: string;
}