import { Section1 } from './sections/Section1';
import { Section2 } from './sections/Section2';
import { Section3 } from './sections/Section3';
import { Section4 } from './sections/Section4';
import { Section5 } from './sections/Section5';
import { Section6 } from './sections/Section6';
import { Section7 } from './sections/Section7';
import { Section8 } from './sections/Section8';

export default [
  {
    step: 1,
    component: Section1
  },
  {
    step: 2,
    component: Section2
  },
  {
    step: 3,
    component: Section3
  },
  {
    step: 4,
    component: Section4
  },
  {
    step: 5,
    component: Section5
  },
  {
    step: 6,
    component: Section6
  },
  {
    step: 7,
    component: Section7
  },
  {
    step: 8,
    component: Section8
  }
];
