import { AnyAction } from 'redux';
import { SurveyActionTypes } from '../actions/surveys';
import { UserType } from '../../enums/enums';

const initialState = {
  surveys: []
};

export function SurveysReducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case SurveyActionTypes.FETCH:
      return {
        ...state,
        surveys: action.payload.reduce(
          (acc: any[], survey: { clientId: any; target: string; id: any; client: { code: any; name: any } }) => {
            const existent = acc.find((v) => v.id === survey.clientId);
            if (existent) {
              if (UserType.Mentee === survey.target && existent.menteesId === null) {
                existent.menteesId = survey.id;
              } else if (UserType.Mentor === survey.target && existent.mentorsId === null) {
                existent.mentorsId = survey.id;
              } else if (UserType.All === survey.target && existent.surveyId === null) {
                existent.surveyId = survey.id;
              }
              return [...acc];
            } else {
              const json = {
                id: survey.clientId,
                code: survey.client.code,
                name: survey.client.name,
                mentorsId: UserType.Mentor === survey.target ? survey.id : null,
                menteesId: UserType.Mentee === survey.target ? survey.id : null,
                surveyId: UserType.All === survey.target ? survey.id : null
              };
              return [...acc, json];
            }
          },
          []
        )
      };

    case SurveyActionTypes.FETCH_BY_CLIENT:
      return {
        ...state,
        surveys: action.payload
      };

    default:
      return state;
  }
}
