import { GlobalState } from 'little-state-machine';

export function updateAction(state: GlobalState, payload: GlobalState): GlobalState {
  return {
    ...state,
    oneOnOneFinalAssessment: {
      ...state.oneOnOneFinalAssessment,
      ...payload.oneOnOneFinalAssessment
    }
  };
}
