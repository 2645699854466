import React, { useEffect } from 'react';
import { FormControl, FormLabel, FormHelperText, Grid } from '@material-ui/core';
import { useStyles } from '../../styles';
import { useStateMachine } from 'little-state-machine';
import { useForm, Controller } from 'react-hook-form';
import { Rating, Button, Fade } from '@mui/material';
import { IFormStep } from '../finalSessionCoacingSurvey.interface';
import { updateAction } from '../actions';
import StarIcon from '@mui/icons-material/Star';

export const Section2: React.FC<IFormStep> = ({ step, setStep }) => {
  const { state, actions } = useStateMachine({
    updateAction
  });
  const {
    handleSubmit,
    getValues,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: state.finalSessionCoachingSurvey.formData as any
  });
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleBack = () => {
    const partialData = getValues();
    actions.updateAction({ finalSessionCoachingSurvey: { formData: partialData } } as any);
    setStep(step - 1);
  };

  const onSubmit = (data: any) => {
    console.log('onSubmit Step 2: ', data);
    actions.updateAction({ finalSessionCoachingSurvey: { formData: data } } as any);
    setStep(step + 1);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Fade in={true}>
        <Grid className={classes.formContent} container spacing={5} alignItems="center" justifyContent="center">
          <Grid container item xs={12} direction="column">
            <FormControl>
              <FormLabel className={classes.formLabel}>
                If applicable, on a scale of 1-5, how would you rate your private coaching session? *
              </FormLabel>
              <Controller
                render={({ field }) => (
                  <Rating
                    {...field}
                    max={5}
                    name="hover-feedback"
                    precision={1}
                    icon={<StarIcon sx={{ color: '#efb906', mr: 4, mb: 2, fontSize: '3rem' }} />}
                    emptyIcon={<StarIcon sx={{ opacity: 0.55, mr: 4, mb: 2, fontSize: '3rem' }} />}
                  />
                )}
                rules={{ required: true }}
                name="privateRating"
                control={control}
              />
              {errors?.privateRating?.type === 'required' && (
                <FormHelperText style={{ color: 'red' }}>Required</FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item className={classes.buttonContainter}>
            <Button className={classes.button} variant="outlined" color="success" onClick={() => handleBack()}>
              BACK
            </Button>
          </Grid>

          <Grid item className={classes.buttonContainter}>
            <Button className={classes.button} variant="contained" color="success" type="submit">
              NEXT
            </Button>
          </Grid>
        </Grid>
      </Fade>
    </form>
  );
};
