import React, { useEffect } from 'react';
import { FormControl, FormLabel, FormHelperText, Grid } from '@material-ui/core';
import { useStyles } from '../../styles';
import { useStateMachine } from 'little-state-machine';
import { useForm } from 'react-hook-form';
import { IFormStep } from '../finalSessionCoacingSurvey.interface';
import { updateAction } from '../actions';
import { Fade, FormGroup, Button } from '@mui/material';

export const Section6: React.FC<IFormStep> = ({ step, setStep }) => {
  const { state, actions } = useStateMachine({
    updateAction
  });
  const {
    handleSubmit,
    getValues,
    register,
    formState: { errors }
  } = useForm({
    defaultValues: state.finalSessionCoachingSurvey.formData as any
  });
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleBack = () => {
    const partialData = getValues();
    actions.updateAction({ finalSessionCoachingSurvey: { formData: partialData } } as any);
    setStep(step - 1);
  };

  const onSubmit = (data: any) => {
    console.log('onSubmit Step 6: ', data);
    actions.updateAction({ finalSessionCoachingSurvey: { formData: data } } as any);
    setStep(step + 1);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Fade in={true}>
        <Grid className={classes.formContent} container spacing={5} alignItems="center" justifyContent="center">
          <Grid container item xs={12} direction="column">
            <FormControl>
              <FormLabel className={classes.formLabel}>
                What aspects of the leadership program did you find most valuable or impactful? *
              </FormLabel>
              <FormGroup>
                {['The Coach', 'Group Learning Style', 'Resources', 'Topics and Content'].map((optionValue, index) => (
                  <Grid item xs={12} key={index}>
                    <FormLabel key={optionValue} className={classes.checkbox}>
                      <input
                        {...register('valuableAspects', { required: true })}
                        type="checkbox"
                        value={optionValue}
                        name="valuableAspects"
                        style={{
                          margin: '20px 30px 20px 30px',
                          transform: 'scale(1.5)'
                        }}
                      />
                      <span style={{ fontSize: '18px', color: 'grey', marginTop: '5px' }}>{optionValue}</span>
                    </FormLabel>
                  </Grid>
                ))}
              </FormGroup>

              {errors.valuableAspects?.type === 'required' && (
                <FormHelperText style={{ color: 'red' }}>Required</FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item className={classes.buttonContainter}>
            <Button className={classes.button} variant="outlined" color="success" onClick={() => handleBack()}>
              BACK
            </Button>
          </Grid>

          <Grid item className={classes.buttonContainter}>
            <Button className={classes.button} variant="contained" color="success" type="submit">
              NEXT
            </Button>
          </Grid>
        </Grid>
      </Fade>
    </form>
  );
};
