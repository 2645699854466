import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FormControlLabel, FormControl, FormHelperText, Grid, FormLabel } from '@material-ui/core';
import { useStyles } from '../../styles';
import { useStateMachine } from 'little-state-machine';
import { updateAction } from '../../actions';
import { useForm, Controller } from 'react-hook-form';
import api from '../../../../../services/api';
import { Colors, UserType } from '../../../../../enums/enums';
import { IFormProps } from './section.interface';
import { Divider, Typography, Button, Radio, RadioGroup, Stack, Box, TextField } from '@mui/material';

interface IFormClientProps extends IFormProps {
  survey: { id: string; clientId: string; company: string; surveyId: string };
}

const Section2: React.FC<IFormClientProps> = ({ step, setStep, survey }) => {
  const { state, actions } = useStateMachine({
    updateAction
  });

  const {
    handleSubmit,
    getValues,
    control,
    formState: { errors, isSubmitSuccessful }
  } = useForm({
    defaultValues: state?.formDetails?.all
  });

  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    if (isSubmitSuccessful) {
      handleComplete();
      setStep(step + 1);
    }
  }, [state]);

  const handleBack = () => {
    const partialData = getValues();
    actions.updateAction({ formDetails: { all: partialData } } as any);
    setStep(step - 1);
  };

  const handleComplete = (): void => {
    const data = {
      type: UserType.All,
      client: survey?.company,
      clientId: survey?.clientId,
      surveyId: survey?.id,
      email: state.formDetails.all.email,
      formResponse: state.formDetails.all
    };
    console.log('Data ONBOARDING', data);
    api.post('/surveys/onboarding-general', data).then((value) => {
      // clear out session storage
      actions.updateAction({
        formDetails: {
          all: null
        }
      } as any);
    });
  };

  const onSubmit = (data: any) => {
    actions.updateAction({
      formDetails: {
        all: {
          ...data,
          clientId: survey?.clientId,
          clientName: survey?.company
        }
      }
    } as any);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Divider sx={{ mb: 6, ml: 4 }} />
      <Grid style={{ margin: 0 }} container spacing={5} alignItems="center" justifyContent="center">
        <Grid style={{ justifyContent: 'center', alignItems: 'center' }} item>
          <Stack sx={{ width: '667px', ml: 4 }} spacing={3}>
            <Box sx={{ mt: -4 }}>
              <FormControl style={{ width: '100%' }}>
                <FormLabel className={classes.formLabel}>Company *</FormLabel>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="Enter your company name *"
                      fullWidth
                      variant="outlined"
                      color="success"
                    />
                  )}
                  name="company"
                  control={control}
                  rules={{ required: true }}
                />
                {errors?.company?.type === 'required' && (
                  <FormHelperText className={classes.errorText}>Required</FormHelperText>
                )}
              </FormControl>
            </Box>

            <Box>
              <FormControl style={{ width: '100%' }}>
                <FormLabel className={classes.formLabel}>Current Job Title? *</FormLabel>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="Enter your current job title"
                      fullWidth
                      variant="outlined"
                      color="success"
                    />
                  )}
                  name="title"
                  control={control}
                  rules={{ required: true }}
                />
                {errors?.title?.type === 'required' && (
                  <FormHelperText className={classes.errorText}>Required</FormHelperText>
                )}
              </FormControl>
            </Box>

            <Box>
              <FormControl>
                <FormLabel className={classes.formLabel}>Years of Experience in Your Field *</FormLabel>
                <Controller
                  render={({ field }) => (
                    <RadioGroup row {...field}>
                      {['0-5', '6-10', '11-15', '16-20', '21+'].map((experience, index) => (
                        <FormControlLabel
                          key={index}
                          value={experience}
                          control={<Radio color="success" />}
                          label={experience}
                        />
                      ))}
                    </RadioGroup>
                  )}
                  name="experience"
                  control={control}
                  rules={{ required: true }}
                />
                {errors?.experience?.type === 'required' && (
                  <FormHelperText className={classes.errorText}>Required</FormHelperText>
                )}
              </FormControl>
            </Box>

            <Box>
              <FormControl style={{ width: '100%' }}>
                <FormLabel className={classes.formLabel}>LinkedIn Profile URL *</FormLabel>
                <Typography sx={{ color: 'grey', fontSize: '0.85rem', mb: 2 }}>
                  Copy and paste the entire link to your profile including https://
                </Typography>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="e.g. https://www.linkedin.com/in/your-profile/"
                      fullWidth
                      variant="outlined"
                    />
                  )}
                  name="linkedin"
                  control={control}
                  rules={{ required: true }}
                />
                {errors?.linkedin?.type === 'required' && (
                  <FormHelperText className={classes.errorText}>Required</FormHelperText>
                )}
              </FormControl>
            </Box>
          </Stack>
          <Stack sx={{ width: '667px', ml: 4 }} spacing={3}>
            <Box>
              <Typography sx={{ mt: 3 }} className={classes.formLabel}>
                Career Goals *
              </Typography>
              <Typography sx={{ color: 'grey', fontSize: '0.85rem', mb: 2, width: '667px' }}>
                What are your career goals or topics you want to learn more about.
              </Typography>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    multiline
                    rows={3}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    color="success"
                  />
                )}
                name="careerGoals"
                control={control}
                rules={{ required: true }}
              />
              {errors?.careerGoals?.type === 'required' && (
                <FormHelperText className={classes.errorText}>Required</FormHelperText>
              )}
            </Box>

            <Box>
              <Typography className={classes.formLabel}>Preferred Learning Style </Typography>
              <Typography sx={{ color: 'grey', fontSize: '0.85rem', mb: 2, width: '667px' }}>
                How do you prefer to learn? (e.g., one-on-one sessions, group sessions, through written material, video
                content, etc.)
              </Typography>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    color="success"
                  />
                )}
                name="learningStyle"
                control={control}
              />
            </Box>

            <Box>
              <Typography className={classes.formLabel}>Accessibility Needs </Typography>
              <Typography sx={{ color: 'grey', fontSize: '0.85rem', mb: 2, width: '667px' }}>
                Do you have any accessibility needs or preferences for communication and material formats that we should
                be aware of to better accommodate you during the program?
              </Typography>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    color="success"
                  />
                )}
                name="accessibility"
                control={control}
              />
            </Box>
          </Stack>
        </Grid>

        <Grid item xs={12} style={{ marginLeft: 50, marginTop: 30 }}>
          <FormControl>
            <Controller
              render={({ field }) => (
                <RadioGroup row {...field}>
                  <FormControlLabel
                    className={classes.formLabel}
                    value="agree"
                    control={<Radio sx={{ mr: 2 }} color="success" />}
                    label="I confirm all information I entered is accurate and  by participating, I agree to uphold confidentiality and non-disclosure pertaining to all sessions and programs facilitated by BestHuman."
                    labelPlacement="end"
                  />
                </RadioGroup>
              )}
              name="confidentiality"
              control={control}
              rules={{ required: true }}
            />
            {errors?.confidentiality?.type === 'required' && (
              <FormHelperText className={classes.errorText}>Required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item className={classes.buttonContainter}>
          <Button className={classes.button} variant="outlined" color="success" onClick={() => handleBack()}>
            BACK
          </Button>
        </Grid>

        <Grid item className={classes.buttonContainter}>
          <Button className={classes.button} variant="contained" color="success" type="submit">
            SUBMIT
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default Section2;