import { AnyAction } from 'redux';
import { TasksActionTypes } from '../actions/tasks';

const initialState = {
  tasksCount: 0,
  userTasksAndResponses: {},
};

export function TasksReducer(state = initialState, action: AnyAction) {
  if (!state) {
    return initialState;
  }

  switch (action.type) {
    case TasksActionTypes.FETCH_TASKS_RESPONSES:
      return {
        ...state,
        userTasksAndResponses: action.payload
      };

    case TasksActionTypes.SET_COUNT:
      return {
        ...state,
        tasksCount: action.payload
      };

    default:
      return state;
  }
}
