import React from 'react';
import { Alert, Fade, Typography } from '@mui/material';
import { Colors } from '../../enums/enums';

export default function TimeBasedGreeting(props) {
  const userName = props?.userName;
  let timeBasedGreeting = '';
  let timeBasedEmoji = '';
  const d = new Date();
  const time = d.getHours();

  if (time < 12 && time >= 5) {
    timeBasedGreeting = 'Good morning, ';
    timeBasedEmoji = '♨';
  } else if (time >= 12 && time < 18) {
    timeBasedGreeting = 'Good afternoon, ';
    timeBasedEmoji = '𖤓';
  } else if (time >= 18 && time <= 23) {
    timeBasedGreeting = 'Good evening, ';
    timeBasedEmoji = '⊹₊⟡⋆';
  } else {
    timeBasedGreeting = 'Hi, ';
    timeBasedEmoji = '☻';
  }

  return (
    <>
      <Fade timeout={1000} in={true}>
        <Typography
          sx={{
            color: Colors.ButtonGreen,
            fontWeight: 'bold',
            fontSize: '1.2rem',
            textAlign: 'end',
            animation: 'flash 4s infinite',
            '@keyframes flash': {
              '0%, 100%': { opacity: 1 },
              '50%': { opacity: 0.6 }
            }
          }}
        >
          <span style={{ marginRight: '5px', color: Colors.TextElevated }}> {timeBasedEmoji}</span> {timeBasedGreeting}
          {userName?.split(' ')[0]}
        </Typography>
      </Fade>
    </>
  );
}