import React from 'react';
import DialogContent from '@mui/material/DialogContent';
import {
  Box,
  FormControl,
  FormLabel,
  Rating,
  DialogTitle,
  Dialog,
  Button,
  Typography,
  TextField,
  Stack,
  DialogActions
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import StarIcon from '@mui/icons-material/Star';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import HomeIcon from '@mui/icons-material/Home';
import { useHistory } from 'react-router-dom';
import { Colors, TaskType } from '../../../enums/enums';

interface Props {
  isOpen: boolean;
  taskId: string;
  groupId: string;
  handleClose: () => void;
}

const OneOnOneMidCohortSurvey = (props: Props) => {
  const [coachRating, setCoachRating] = React.useState<number | null>(null);
  const [coachRatingHover, setCoachRatingHover] = React.useState(-1);
  const [experienceRating, setExperienceRating] = React.useState<number | null>(null);
  const [experienceRatingHover, setExperienceRatingHover] = React.useState(-1);
  const [coachEffectiveness, setCoachEffectiveness] = React.useState<number | null>(null);
  const [coachEffectivenessHover, setCoachEffectivenessHover] = React.useState(-1);
  const [communication, setCommunication] = React.useState<number | null>(null);
  const [communicationHover, setCommunicationHover] = React.useState(-1);
  const userId = localStorage.getItem('LOCELLE:USER');
  const history = useHistory();

  const submitResponse = (data): void => {
    try {
      const surveyData = {
        userId: userId,
        taskId: props.taskId,
        groupId: props.groupId,
        taskName: TaskType.OneOnOneMidCohortSurvey,
        formResponse: data
      };
      api
        .post('/tasks/response', surveyData)
        .then(({ data }) => {
          toast.success('Thank you for submitting your responses');
          props.handleClose();
        })
        .catch(() => toast.error('Error submitting response'));
    } catch (e) {
      toast.error(`Error submitting response ${e?.data?.message}`);
    }
  };

  const labelsQuestionOneTwo: { [index: string]: string } = {
    1: 'Very Unsatisfied',
    2: 'Unsatisfied',
    3: 'Neutral',
    4: 'Satisfied',
    5: 'Very Satisfied'
  };

  const labelsQuestionThree: { [index: string]: string } = {
    1: 'Strongly Disagree',
    2: 'Disagree',
    3: 'Neutral',
    4: 'Agree',
    5: 'Strongly Agree'
  };
  const labelsQuestionFour: { [index: string]: string } = {
    1: 'Poor',
    2: 'Fair',
    3: 'Good',
    4: 'Excellent'
  };

  function getLabelQuestionsOne(value: number) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labelsQuestionOneTwo[value]}`;
  }

  function getLabelQuestionsTwo(value: number) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labelsQuestionOneTwo[value]}`;
  }

  function getLabelQuestionThree(value: number) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labelsQuestionThree[value]}`;
  }

  function getLabelQuestionFour(value: number) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labelsQuestionFour[value]}`;
  }

  const { handleSubmit, control } = useForm({
    defaultValues: {
      ratingCoach: null,
      ratingExperience: null,
      coachEffectiveness: null,
      communication: null,
      sessionsImprovement: null
    }
  });

  return (
    <Dialog maxWidth="lg" open={props.isOpen} onClose={props.handleClose}>
      <DialogTitle sx={{ fontSize: '1.5rem', backgroundColor: Colors.BackgroundDrawer }}>
        <Button
          startIcon={<HomeIcon />}
          sx={{
            position: 'absolute',
            right: '16px',
            backgroundColor: '#9f9595',
            color: '#FFFFFF',

            width: 190,
            '&:hover': {
              backgroundColor: '#7c7272'
            }
          }}
          onClick={() => {
            history.push(`/tasks`);
            props.handleClose();
          }}
        >
          Return to Portal
        </Button>
        <Typography sx={{ fontSize: '1.6rem', color: Colors.TextElevated }}>Coach Fit Assessment</Typography>
        <Typography sx={{ color: 'grey' }}>Approximate completion time: 2 minutes</Typography>
      </DialogTitle>
      <form onSubmit={handleSubmit(submitResponse)}>
        <DialogContent sx={{ mt: 2, mr: 6 }}>
          <Stack direction="column" spacing={2}>
            <FormControl>
              <FormLabel style={{ marginLeft: '20px', fontSize: '1.1rem', color: Colors.TextElevated }}>
                1. How satisfied are you with your overall experience with your coach so far? *
              </FormLabel>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Controller
                  render={({ field }) => (
                    <Rating
                      {...field}
                      sx={{ mt: 2, ml: 3 }}
                      name="hover-feedback"
                      value={coachRating}
                      precision={1}
                      getLabelText={getLabelQuestionsOne}
                      onChange={(event, newValue) => {
                        field.onChange(newValue);
                        setCoachRating(newValue);
                      }}
                      onChangeActive={(event, newHover) => {
                        setCoachRatingHover(newHover);
                      }}
                      icon={<StarIcon sx={{ color: '#eaaa05', mr: 3, fontSize: '2.5rem', mb: 2 }} />}
                      emptyIcon={<StarIcon sx={{ opacity: 0.55, fontSize: '2.5rem', mr: 3, mb: 2 }} />}
                    />
                  )}
                  name="ratingCoach"
                  control={control}
                />
                {coachRating !== null && (
                  <Box sx={{ ml: 2, color: Colors.ButtonGreen, fontSize: '1rem', pt: 1 }}>
                    {labelsQuestionOneTwo[coachRatingHover !== -1 ? coachRatingHover : coachRating]}
                  </Box>
                )}
              </Box>
            </FormControl>

            <FormControl>
              <FormLabel
                style={{ marginLeft: '30px', marginTop: '20px', fontSize: '1.1rem', color: Colors.TextElevated }}
              >
                2. How satisfied are you so far with the overall experience? *
              </FormLabel>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Controller
                  render={({ field }) => (
                    <Rating
                      {...field}
                      sx={{ mt: 3, ml: 3 }}
                      name="hover-feedback"
                      value={experienceRating}
                      precision={1}
                      getLabelText={getLabelQuestionsTwo}
                      onChange={(event, newValue) => {
                        field.onChange(newValue);
                        setExperienceRating(newValue);
                      }}
                      onChangeActive={(event, newHover) => {
                        setExperienceRatingHover(newHover);
                      }}
                      icon={<StarIcon sx={{ color: '#eaaa05', mr: 3, fontSize: '2.5rem', mb: 2 }} />}
                      emptyIcon={<StarIcon sx={{ opacity: 0.55, fontSize: '2.5rem', mr: 3, mb: 2 }} />}
                    />
                  )}
                  name="ratingExperience"
                  control={control}
                />
                {experienceRating !== null && (
                  <Box sx={{ ml: 2, color: Colors.ButtonGreen, fontSize: '1rem', pt: 1 }}>
                    {labelsQuestionOneTwo[experienceRatingHover !== -1 ? experienceRatingHover : experienceRating]}
                  </Box>
                )}
              </Box>
            </FormControl>

            <FormControl>
              <FormLabel
                style={{ marginLeft: '30px', marginTop: '20px', fontSize: '1.1rem', color: Colors.TextElevated }}
              >
                3. Do you feel your coach understands your goals and challenges effectively? *
              </FormLabel>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Controller
                  render={({ field }) => (
                    <Rating
                      {...field}
                      sx={{ mt: 3, ml: 3 }}
                      name="hover-feedback"
                      value={coachEffectiveness}
                      precision={1}
                      getLabelText={getLabelQuestionThree}
                      onChange={(event, newValue) => {
                        field.onChange(newValue);
                        setCoachEffectiveness(newValue);
                      }}
                      onChangeActive={(event, newHover) => {
                        setCoachEffectivenessHover(newHover);
                      }}
                      icon={<StarIcon sx={{ color: '#eaaa05', mr: 3, fontSize: '2.5rem', mb: 2 }} />}
                      emptyIcon={<StarIcon sx={{ opacity: 0.55, fontSize: '2.5rem', mr: 3, mb: 2 }} />}
                    />
                  )}
                  name="coachEffectiveness"
                  control={control}
                />
                {coachEffectiveness !== null && (
                  <Box sx={{ ml: 2, color: Colors.ButtonGreen, fontSize: '1rem', pt: 1 }}>
                    {labelsQuestionThree[coachEffectivenessHover !== -1 ? coachEffectivenessHover : coachEffectiveness]}
                  </Box>
                )}
              </Box>
            </FormControl>

            <FormControl>
              <FormLabel
                style={{ marginLeft: '30px', marginTop: '20px', fontSize: '1.1rem', color: Colors.TextElevated }}
              >
                4. How would you rate the level of communication and feedback between you and your coach? *
              </FormLabel>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Controller
                  render={({ field }) => (
                    <Rating
                      {...field}
                      sx={{ mt: 3, ml: 3 }}
                      name="hover-feedback"
                      value={communication}
                      max={4}
                      precision={1}
                      getLabelText={getLabelQuestionFour}
                      onChange={(event, newValue) => {
                        field.onChange(newValue);
                        setCommunication(newValue);
                      }}
                      onChangeActive={(event, newHover) => {
                        setCommunicationHover(newHover);
                      }}
                      icon={<StarIcon sx={{ color: '#eaaa05', mr: 3, fontSize: '2.5rem', mb: 2 }} />}
                      emptyIcon={<StarIcon sx={{ opacity: 0.55, fontSize: '2.5rem', mr: 3, mb: 2 }} />}
                    />
                  )}
                  name="communication"
                  control={control}
                />
                {communication !== null && (
                  <Box sx={{ ml: 2, color: Colors.ButtonGreen, fontSize: '1rem', pt: 1 }}>
                    {labelsQuestionFour[communicationHover !== -1 ? communicationHover : communication]}
                  </Box>
                )}
              </Box>
            </FormControl>

            <FormControl>
              <FormLabel
                style={{ marginLeft: '30px', marginTop: '20px', fontSize: '1.1rem', color: Colors.TextElevated }}
              >
                5. Is there anything specific you would like to see improved or adjusted in your coaching sessions?
              </FormLabel>
              <Controller
                render={({ field }) => (
                  <TextField multiline rows={4} fullWidth color="success" {...field} sx={{ mb: 2, mt: 2, mx: 3 }} />
                )}
                name="sessionsImprovement"
                control={control}
              />
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ backgroundColor: Colors.BackgroundDrawer, p: 2 }}>
          <Button
            variant="contained"
            color="success"
            size="large"
            style={{ backgroundColor: Colors.ButtonGreen }}
            type="submit"
          >
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default OneOnOneMidCohortSurvey;