import React from 'react';
import { createStyles, List, makeStyles, Theme } from '@material-ui/core';
import { IconButton, ListItem } from '@mui/material';
import { Link } from 'react-scroll';
import { Button } from '@mui/material';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import { Colors } from '../../enums/enums';

interface Props {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    question: {
      fontSize: '16px',
      font: 'Roboto',
      color: '#0077DB',
      fontWeight: 500,
      top: '32px'
    },
    '@keyframes flicker': {
      from: {
        opacity: 1
      },
      to: {
        opacity: 0.3
      }
    },
    flicker: {
      animationName: '$flicker',
      animationDuration: '1000ms',
      animationIterationCount: 'infinite',
      animationDirection: 'alternate',
      animationTimingFunction: 'ease-in-out'
    }
  })
);

const QuestionsHeaderCoaching = (props: Props) => {
  const classes = useStyles();

  return (
    <div>
      <IconButton
        onClick={() =>
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          })
        }
      >
        <ArrowCircleUpIcon
          className={classes.flicker}
          sx={{ fontSize: '3rem', position: 'fixed', right: 50, top: 100 }}
          color="success"
        />
      </IconButton>
      <List>
        <ListItem sx={{ cursor: 'pointer' }}>
          <Link to="q1" spy={true} smooth={true} className={classes.question}>
            What is coaching, and how can it benefit me?
          </Link>
        </ListItem>
        <ListItem sx={{ cursor: 'pointer' }}>
          <Link to="q2" spy={true} smooth={true} className={classes.question}>
            How does the coaching platform work?
          </Link>
        </ListItem>
        <ListItem sx={{ cursor: 'pointer' }}>
          <Link to="q3" spy={true} smooth={true} className={classes.question}>
            What types of coaching services are available on the platform?
          </Link>
        </ListItem>
        <ListItem sx={{ cursor: 'pointer' }}>
          <Link to="q4" spy={true} smooth={true} className={classes.question}>
            How do you choose the right coach for my needs?
          </Link>
        </ListItem>
        <ListItem sx={{ cursor: 'pointer' }}>
          <Link to="q5" spy={true} smooth={true} className={classes.question}>
            How do I schedule a coaching session?
          </Link>
        </ListItem>
        <ListItem sx={{ cursor: 'pointer' }}>
          <Link to="q6" spy={true} smooth={true} className={classes.question}>
            What are the qualifications and credentials of the coaches on the platform?
          </Link>
        </ListItem>
        <ListItem sx={{ cursor: 'pointer' }}>
          <Link to="q7" spy={true} smooth={true} className={classes.question}>
            Can I change coaches if I'm not satisfied with my current one?{' '}
          </Link>
        </ListItem>
        <ListItem sx={{ cursor: 'pointer' }}>
          <Link to="q8" spy={true} smooth={true} className={classes.question}>
            Is my information and data kept confidential and secure?
          </Link>
        </ListItem>
        <ListItem sx={{ cursor: 'pointer' }}>
          <Link to="q9" spy={true} smooth={true} className={classes.question}>
            How long are coaching sessions?
          </Link>
        </ListItem>
        <ListItem sx={{ cursor: 'pointer' }}>
          <Link to="q10" spy={true} smooth={true} className={classes.question}>
            Can I have coaching sessions at my preferred time and timezone?
          </Link>
        </ListItem>
        <ListItem sx={{ cursor: 'pointer' }}>
          <Link to="q11" spy={true} smooth={true} className={classes.question}>
            What if I need to reschedule or cancel a coaching session?
          </Link>
        </ListItem>
        <ListItem sx={{ cursor: 'pointer' }}>
          <Link to="q12" spy={true} smooth={true} className={classes.question}>
            Can I access coaching materials or resources outside of the sessions?
          </Link>
        </ListItem>
        <ListItem sx={{ cursor: 'pointer' }}>
          <Link to="q13" spy={true} smooth={true} className={classes.question}>
            Can I provide feedback or rate my coaching experience?
          </Link>
        </ListItem>
        <ListItem sx={{ cursor: 'pointer' }}>
          <Link to="q14" spy={true} smooth={true} className={classes.question}>
            What if I have technical issues during a coaching session?
          </Link>
        </ListItem>
        <ListItem sx={{ cursor: 'pointer' }}>
          <Link to="q15" spy={true} smooth={true} className={classes.question}>
            How do I join coaching sessions?
          </Link>
        </ListItem>
        <ListItem sx={{ cursor: 'pointer' }}>
          <Link to="q16" spy={true} smooth={true} className={classes.question}>
            Are there any additional support or resources available besides one-on-one coaching?
          </Link>
        </ListItem>
        <ListItem sx={{ cursor: 'pointer' }}>
          <Link to="q17" spy={true} smooth={true} className={classes.question}>
            Do I get a certificate?
          </Link>
        </ListItem>
        <ListItem>
          <Button
            variant="contained"
            color="success"
            style={{ color: 'white', backgroundColor: Colors.ButtonGreen, fontSize: '12px', marginTop: '20px' }}
          >
            <a
              href="mailto:team@locelle.com?subject=[FAQ] New Question"
              style={{ textDecoration: 'none', color: 'white' }}
            >
              Submit a question
            </a>
          </Button>
        </ListItem>
      </List>
    </div>
  );
};

export default QuestionsHeaderCoaching;
