export const notesEditorConfig = {
  height: '95%',
  menu: {
    file: { title: 'File', items: 'export print' },
    edit: { title: 'Edit', items: 'undo redo | cut copy paste pastetext | selectall | searchreplace' },
    view: { title: 'View', items: 'fullscreen' },
    insert: { title: 'Insert', items: '' },
    format: {
      title: 'Format',
      items: 'bold italic underline | styles blocks fontfamily fontsize align | forecolor backcolor | removeformat'
    },
    tools: { title: 'Tools', items: 'wordcount' },
    table: { title: 'Table', items: '' },
    help: { title: 'Help', items: '' }
  },
  plugins: ['lists', 'anchor', 'searchreplace', 'fullscreen', 'wordcount'],
  toolbar:
    'undo redo blocks ' +
    'bold italic underline | fontsize forecolor backcolor | alignleft aligncenter ' +
    'alignright | bullist numlist outdent indent | searchreplace fullscreen ' +
    'removeformat ',
  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
};
