import React, { useEffect } from 'react';
import { FormControl, FormLabel, FormHelperText, Grid } from '@material-ui/core';
import { useStyles } from '../../styles';
import { useStateMachine } from 'little-state-machine';
import { useForm, Controller } from 'react-hook-form';
import { IFormStep } from '../midCohortCoacingSurvey.interface';
import { updateAction } from '../actions';
import { Typography, Button, Fade } from '@mui/material';
import Rating, { IconContainerProps } from '@mui/material/Rating';
import { styled } from '@mui/material/styles';

export const Section9: React.FC<IFormStep> = ({ step, setStep }) => {
  const { state, actions } = useStateMachine({
    updateAction
  });
  const {
    handleSubmit,
    getValues,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: state.midCohortCoachingSurvey.formData as any
  });
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleBack = () => {
    const partialData = getValues();
    actions.updateAction({ midCohortCoachingSurvey: { formData: partialData } } as any);
    setStep(step - 1);
  };

  const onSubmit = (data: any) => {
    console.log('onSubmit Step 9: ', data);
    actions.updateAction({ midCohortCoachingSurvey: { formData: data } } as any);
    setStep(step + 1);
  };

  const StyledRating = styled(Rating)(({ theme }) => ({
    '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
      color: theme.palette.action.disabled
    }
  }));

  const customIcons: {
    [index: string]: {
      icon: React.ReactElement;
    };
  } = {
    1: {
      icon: (
        <Typography
          sx={{
            fontSize: '3.5rem',
            mr: 1,
            borderRadius: 1,
            border: '2px solid lightGrey',
            width: 70,
            height: 90,
            textAlign: 'center'
          }}
        >
          1
        </Typography>
      )
    },
    2: {
      icon: (
        <Typography
          sx={{
            fontSize: '3.5rem',
            mr: 1,
            borderRadius: 1,
            border: '2px solid lightGrey',
            width: 70,
            height: 90,
            textAlign: 'center'
          }}
        >
          2
        </Typography>
      )
    },
    3: {
      icon: (
        <Typography
          sx={{
            fontSize: '3.5rem',
            mr: 1,
            borderRadius: 1,
            border: '2px solid lightGrey',
            width: 70,
            height: 90,
            textAlign: 'center'
          }}
        >
          3
        </Typography>
      )
    },
    4: {
      icon: (
        <Typography
          sx={{
            fontSize: '3.5rem',
            mr: 1,
            borderRadius: 1,
            border: '2px solid lightGrey',
            width: 70,
            height: 90,
            textAlign: 'center'
          }}
        >
          4
        </Typography>
      )
    },
    5: {
      icon: (
        <Typography
          sx={{
            fontSize: '3.5rem',
            mr: 1,
            borderRadius: 1,
            border: '2px solid lightGrey',
            width: 70,
            height: 90,
            textAlign: 'center'
          }}
        >
          5
        </Typography>
      )
    },
    6: {
      icon: (
        <Typography
          sx={{
            fontSize: '3.5rem',
            mr: 1,
            borderRadius: 1,
            border: '2px solid lightGrey',
            width: 70,
            height: 90,
            textAlign: 'center'
          }}
        >
          6
        </Typography>
      )
    },
    7: {
      icon: (
        <Typography
          sx={{
            fontSize: '3.5rem',
            mr: 1,
            borderRadius: 1,
            border: '2px solid lightGrey',
            width: 70,
            height: 90,
            textAlign: 'center'
          }}
        >
          7
        </Typography>
      )
    },
    8: {
      icon: (
        <Typography
          sx={{
            fontSize: '3.5rem',
            mr: 1,
            borderRadius: 1,
            border: '2px solid lightGrey',
            width: 70,
            height: 90,
            textAlign: 'center'
          }}
        >
          8
        </Typography>
      )
    },
    9: {
      icon: (
        <Typography
          sx={{
            fontSize: '3.5rem',
            mr: 1,
            borderRadius: 1,
            border: '2px solid lightGrey',
            width: 70,
            height: 90,
            textAlign: 'center'
          }}
        >
          9
        </Typography>
      )
    },
    10: {
      icon: (
        <Typography
          sx={{
            fontSize: '3.5rem',
            borderRadius: 1,
            border: '2px solid lightGrey',
            width: 70,
            height: 90,
            textAlign: 'center'
          }}
        >
          10
        </Typography>
      )
    }
  };

  function IconContainer(props: IconContainerProps) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value].icon}</span>;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Fade in={true}>
        <Grid container spacing={5} alignItems="center" justifyContent="center">
          <Grid container item xs={12} direction="column">
            <FormControl>
              <FormLabel className={classes.formLabel} style={{ marginLeft: 80, marginTop: -80 }}>
                How are you finding your coach? *
                <Typography style={{ marginTop: 20, marginBottom: 30 }}>
                  We would love to hear how you are finding your coach.
                </Typography>
              </FormLabel>
              <Controller
                render={({ field }) => (
                  <StyledRating
                    max={10}
                    {...field}
                    name="highlight-selected-only"
                    IconContainerComponent={IconContainer}
                    highlightSelectedOnly
                  />
                )}
                name="coachRating"
                control={control}
                rules={{ required: true }}
              />
              {errors?.coachRating?.type === 'required' && (
                <FormHelperText className={classes.errorText}>Required</FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item className={classes.buttonContainter}>
            <Button className={classes.button} variant="outlined" color="success" onClick={() => handleBack()}>
              BACK
            </Button>
          </Grid>

          <Grid item className={classes.buttonContainter}>
            <Button className={classes.button} variant="contained" color="success" type="submit">
              NEXT
            </Button>
          </Grid>
        </Grid>
      </Fade>
    </form>
  );
};
