
import api from "../../services/api";


export enum MatchesActionTypes {
  FIND = "MATCH_FIND",
}


export function findMatchByMenteeId(menteeId: string) {
  return function(dispatch) {
    return api.get("/matches/details", { 
      params: { menteeId }
    })
      .then(({ data }) => {
        console.log('[MATCHACTION]', menteeId, data)
      dispatch({
        type: MatchesActionTypes.FIND,
        payload: data
      });
    });
  };
}

export function findMatchDataById(id: string, type: string) {
  return function(dispatch) {
    return api.get("/matches/details", { 
      params: { id, type } 
    })
      .then(({ data }) => {
        console.log('[MATCHACTION]', id, type)
      dispatch({
        type: MatchesActionTypes.FIND,
        payload: data
      });
    });
  };
}

export const getMatch = (state) => state.matches.match;