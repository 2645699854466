import { AnyAction } from 'redux';
import { CoachingSessionActionTypes } from '../actions/coachingSessions';
import { ICoachingSessionFullDTO } from '../../pages/Coaching/CoachingSessionFullDTO';
import { CoachingSessionStatus } from '../../pages/Coaching/coaching.enum';
import { getHoursLeftForTheDay } from '../../utils/basic.utils';
import { addDays, isAfter, isFuture, isToday, startOfDay } from 'date-fns';

const initialState = {
  data: [] as ICoachingSessionFullDTO[]
};

export function CoachingSessionsReducer(state = initialState, action: AnyAction) {
  if (!state) {
    return initialState;
  }

  switch (action.type) {
    case CoachingSessionActionTypes.FETCH:
      const sessions = action.payload;
      const today = new Date();
      const tomorrow = addDays(startOfDay(today), 1);

      const foundUpcomingSession = sessions
        .filter((s) => s.status === CoachingSessionStatus.Confirmed && isAfter(new Date(s.date), tomorrow))
        .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

      if (foundUpcomingSession.length) {
        foundUpcomingSession?.map((s) => (s.isUpcoming = true));
        console.log('UPCOMING SESSIONS', foundUpcomingSession);
      }

      const foundTodaysSessions = sessions
        .filter((s) => s.status === CoachingSessionStatus.Confirmed && isToday(new Date(s.date)))
        .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

      if (foundTodaysSessions.length) {
        foundTodaysSessions?.map((s) => (s.isToday = true));
        console.log('TODAY SESSIONS', foundTodaysSessions);
      }

      const foundNextSession = sessions
        .filter((s) => s.status === CoachingSessionStatus.Confirmed && isAfter(new Date(s.date), today))
        .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

      if (!foundTodaysSessions.length && foundNextSession.length) {
        foundNextSession[0].isNext = true;
        console.log('NEXT SESSION', foundNextSession[0]);
      }

      return {
        ...state,
        data: sessions
      };

    case CoachingSessionActionTypes.UPDATE:
      return {
        ...state,
        data: state.data.map((session) => {
          if (session.id === action.payload.id) {
            session.status = action.payload.status;
          }
          return session;
        })
      };

    default:
      return state;
  }
}
