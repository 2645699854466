import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useStyles } from '../styles';
import { Colors } from '../../../../enums/enums';

const SubmissionComplete: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid className={classes.formContent} container spacing={5} alignItems="center" justifyContent="center">
      <Grid className={classes.headerBlock} item xs={12}>
        <Typography
          style={{ marginTop: 30, marginBottom: 40, color: Colors.LogoRed }}
          variant="h4"
          align="center"
          component="h1"
        >
          Thank you for filling out the form!
        </Typography>
        <Typography style={{ color: Colors.Text }} variant="h6" align="center" component="h2" gutterBottom>
          We will contact you shortly with instructions for the next step.
        </Typography>
        <Typography style={{ color: Colors.Text }} variant="h6" align="center" component="h2" gutterBottom>
          Please make sure to whitelist our email address: <b>team@locelle.com</b>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default SubmissionComplete;
