import { AnyAction } from "redux";
import { SessionActionTypes } from "../actions/sessions";

  const initialState = {
    sessions: [],
  }

  export function SessionsReducer(state = initialState, action: AnyAction){

    if (!state) {
      return initialState;
    }

    switch(action.type){

      case SessionActionTypes.FIND: 
        return {
          ...state,
          sessions: action.payload
        }
      case SessionActionTypes.UPDATE: 
        return {
          ...state,
          sessions: state.sessions.map((session) => {
            
            if(session.id === action.payload.id){
              session.status = action.payload.status;
            } 
            return session;
          })
      }
      default:
        return state;
    }

    
  }
