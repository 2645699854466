import React, { useEffect, useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import {
  FormControl,
  DialogTitle,
  Dialog,
  Button,
  Typography,
  Box,
  Stack,
  Divider,
  RadioGroup,
  Radio
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import HomeIcon from '@mui/icons-material/Home';
import { useHistory } from 'react-router-dom';
import { FormControlLabel, FormHelperText } from '@material-ui/core';
import { Colors } from '../../../enums/enums';

interface Props {
  isOpen: boolean;
  userId: string;
  handleClose: () => void;
}

const TermsAndConditionsAgreement = (props: Props) => {
  const history = useHistory();
  const [htmlContent, setHtmlContent] = useState<string | null>(null);

  useEffect(() => {
    if (props.isOpen) {
      fetchText();
    }
  }, [props.isOpen]);

  const fetchText = async () => {
    try {
      const response = await api.post('/tasks/terms-and-conditions');
      const parser = new DOMParser();
      const doc = parser?.parseFromString(response?.data, 'text/html');

      // Get the html content inside <body> tags
      const bodyContent = doc.body.innerHTML;

      setHtmlContent(bodyContent);
    } catch (error) {
      console.error('Error fetching text:', error);
    }
  };

  const submitAgreement = (data) => {
    try {
      const surveyData = { userId: props?.userId, formResponse: data };
      api
        .post('/surveys/terms-and-conditions-agreement', surveyData)
        .then(({ data }) => {
          toast.success('Thank you for signing Terms and Conditions Agreement');
          props?.handleClose();
          history?.push(`/tasks`);
        })
        .catch(() => toast.error('Error signing Terms and Conditions Agreement'));
    } catch (e) {
      toast.error(`Error signing Terms and Conditions Agreement ${e?.data?.message}`);
    }
  };

  const { handleSubmit, control, formState } = useForm({
    defaultValues: {
      agreeToTermsAndConditions: null
    }
  });

  return (
    <Dialog maxWidth="lg" open={props.isOpen} onClose={props.handleClose}>
      <DialogTitle
        sx={{
          position: 'sticky',
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
          backgroundColor: Colors.BackgroundDrawer
        }}
      >
        <Stack direction="row" spacing={2} justifyContent="space-between" textAlign="center">
          <Typography sx={{ fontSize: '1rem', color: Colors.TextElevated }}>
            Please take a minute to read and sign.
          </Typography>

          <Button
            startIcon={<HomeIcon />}
            sx={{
              backgroundColor: '#9f9595',
              color: '#FFFFFF',
              width: 190,
              height: 35,
              '&:hover': {
                backgroundColor: '#7c7272'
              }
            }}
            onClick={() => {
              history?.push(`/tasks`);
              props?.handleClose();
            }}
          >
            Return to Portal
          </Button>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ height: '900px', backgroundColor: Colors.BackgroundMain }}>
        <Box>
          {/* Render HTML content using dangerouslySetInnerHTML */}
          {htmlContent ? (
            <div
              style={{
                marginTop: '20px',
                marginBottom: '30px',
                fontSize: '1.1rem',
                marginLeft: '15px',
                color: 'darkolivegreen'
              }}
              dangerouslySetInnerHTML={{ __html: htmlContent }}
            />
          ) : (
            <p>Loading...</p>
          )}
        </Box>
        <Divider />
        <Box sx={{ ml: 4, my: 2 }}>
          <form onSubmit={handleSubmit(submitAgreement)}>
            <FormControl>
              <Controller
                render={({ field }) => (
                  <RadioGroup row {...field}>
                    <FormControlLabel
                      value="agree"
                      control={<Radio color="success" />}
                      label="I agree"
                      labelPlacement="end"
                    />
                  </RadioGroup>
                )}
                name="agreeToTermsAndConditions"
                control={control}
                rules={{ required: true }}
              />
              {formState.errors?.agreeToTermsAndConditions?.type === 'required' && (
                <FormHelperText color="red">
                  Please click 'I agree' to sign the Terms and Conditions Agreement and then press the Submit button
                </FormHelperText>
              )}
            </FormControl>
            <Button variant="contained" color="success" sx={{ float: 'right', mr: 3, mt: 1 }} type="submit">
              Submit
            </Button>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default TermsAndConditionsAgreement;