import { MidCohortCoachingSurvey } from '../../../models/stateMachineModels';

export const DEFAULT_VALUE = {
  experienceRating: null,
  assignedReadings: null,
  workspace: null,
  slackChannel: null,
  otherSlackChannel: null,
  changes: null,
  newStrengths: null,
  benefits: null,
  areasImproved: null,
  otherAreasImproved: null,
  coachRating: null,
  coachSupport: null,
  coachImprovement: null,
  locelleImprovement: null,
  portalImprovement: null
} as MidCohortCoachingSurvey;
