import { applyMiddleware, createStore } from "redux";
import logger from "redux-logger";
import thunkMiddleware from "redux-thunk";

import * as reducers from "../reducers/index";

const store = createStore(
    reducers.allReducers,
    applyMiddleware(
        // logger,
        thunkMiddleware,
    )
);

export default store;
