import { AnyAction } from 'redux';
import { SurveyActionTypes } from '../actions/surveysResponse';

const initialState = {
  surveysResponse: []
};

export function SurveysResponseReducer(state = initialState, action: AnyAction) {
  if (!state) {
    return initialState;
  }

  switch (action.type) {
    case SurveyActionTypes.FETCH_RESPONSE:
      return {
        ...state,
        surveysResponse: action.payload
      };

    case SurveyActionTypes.FETCH_RESPONSES_BY_USERID:
      return {
        ...state,
        surveysResponse: action.payload
      };

    default:
      return state;
  }
}
